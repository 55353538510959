import { render, staticRenderFns } from "./RecorderControl.vue?vue&type=template&id=6156258a&scoped=true&"
import script from "./RecorderControl.vue?vue&type=script&lang=ts&"
export * from "./RecorderControl.vue?vue&type=script&lang=ts&"
import style0 from "./RecorderControl.vue?vue&type=style&index=0&id=6156258a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6156258a",
  null
  
)

export default component.exports