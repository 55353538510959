





















































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import Slider from "@/components/fundamental/inputs/Slider.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import model_chart_params, {ChartParamsModel} from "@/models/content/charts/model_chart_params"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import InlineTextEditor from "@/components/screen_editor/content/text/InlineTextEditor.vue"
import model_limits from "@/models/content/charts/model_limits"

@Component({
  components: {
    InlineTextEditor,
    TextButton,
    AdvancedEditNumber, AdvancedEditText, AdvancedCheckbox,
    RoundIconButton, InputField, Checkbox, Slider, EditNumber, Column, SelectField, Container, Row, TextField}
})
export default class ChartParamsEditor extends Vue {
  @Prop({type: Object, required: true}) chartParams!: ChartParamsModel
  @Prop({type: Boolean, required: false, default: false}) isBoxPlot!: boolean
  @Prop({type: Boolean, required: false, default: false}) smallContent!: boolean

  inputWidth = 180
  titleWidth = 106

  oldLimits = model_limits.copyLimits(this.chartParams.limits)


  @Watch('chartParams.limits', {deep: true})
  limitsChanged() {
    if (this.chartParams.limits.x_min != this.oldLimits.x_min && this.chartParams.limits.x_min >= this.chartParams.limits.x_max) {
      this.chartParams.limits.x_min = this.oldLimits.x_min

    } else if (this.chartParams.limits.x_max != this.oldLimits.x_max && this.chartParams.limits.x_max <= this.chartParams.limits.x_min) {
      this.chartParams.limits.x_max = this.oldLimits.x_max

    } else if (this.chartParams.limits.y_min != this.oldLimits.y_min && this.chartParams.limits.y_min >= this.chartParams.limits.y_max) {
      this.chartParams.limits.y_min = this.oldLimits.y_min

    } else if (this.chartParams.limits.y_max != this.oldLimits.y_max && this.chartParams.limits.y_max <= this.chartParams.limits.y_min) {
      this.chartParams.limits.y_max = this.oldLimits.y_max
    }

    this.oldLimits = model_limits.copyLimits(this.chartParams.limits)
  }

  reset() {
    if (this.isBoxPlot) {
      model_chart_params.copyParamsElementwise(model_chart_params.template_boxPlot(), this.chartParams)
    } else {
      model_chart_params.copyParamsElementwise(model_chart_params.template_lineChart(), this.chartParams)
    }
  }
}
