export interface SubtitleModel {
  start: number
  stop: number
  text: string
}

export interface SubtitlesRawModel {
  language?: string
  text?: string
  sentences?: Array<SentenceModel>
}

export interface SentenceModel {
  confidence: number
  words: Array<WordModel>
}

export interface WordModel {
  start: number
  stop: number
  text: string
}

export default {
  template(): SubtitleModel {
    return {
      start: 0,
      stop: 0,
      text: ''
    }
  }
}
