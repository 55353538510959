import {RecordingModel} from "@/models/recording/model_recording"
import {FirestoreModel} from "@/models/model_firestore"

export enum SearchCategory {
  id = 'Doc Id',
  algolia = 'Algolia Unit Search',
  collection = 'Collection',
  openRecordings = 'Open Recordings',
  unitStatus = 'Unit Status',
  unitType = 'Unit Type',
  action = 'Action',
  error = 'Error',
  pending = 'Pending',
  proposal = 'Proposals',
  withStaticTable = 'With Static Table',
}

export enum SearchCollection {
  units = 'units',
  curricula = 'curricula',
  recordings = 'recordings',
  recordingsRaw = 'recordings raw',
  users = 'users',
}

export enum SearchOrder {
  undefined = '--',
  lastModifiedAt_desc = 'lastModifiedAt_desc',
  lastModifiedAt_asc = 'lastModifiedAt_asc',
  createdAt_desc = 'createdAt_desc',
  createdAt_asc = 'createdAt_asc',
}

export interface ResultDocModel {
  doc?: FirestoreModel
  unitRecordings?: Array<RecordingModel>
  collection: string
  collectionType: SearchCollection
}

export enum ActionInputType {
  all = 'all',
  term = 'term',
  equation = 'equation',
  time = 'time',
  interval = 'interval',
  dropDown = 'dropDown',
  vector = 'vector',
  ci = 'ci',
  coordinates = 'coordinates',
  paramEq = 'paramEq',
  slideUnlock = 'slideUnlock',
  unsortedList = 'unsortedList',
  continuousText = 'continuousText',
}

export default {
  template_doc(collectionType: SearchCollection): ResultDocModel {
    return {
      doc: undefined,
      collection: '',
      collectionType: collectionType
    }
  }
}