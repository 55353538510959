


























import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import util from "@/util/util"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import model_course, {CourseModel} from "@/models/curriculum/model_course";
import MirandaTest from "@/pages/curriculum_viewer/courses/MirandaTest.vue";

@Component({
  components: {
    MirandaTest, Draggable, AddButton, SelectField, TextButton, SpacerBox, Container, Row, Column}
})
export default class MirandaTests extends Vue {
  @Prop({type: Object, required: true}) course!: CourseModel

  addTest() {
    this.course.tests.push(model_course.template_test())
  }

  requestDeletingTest(index: number) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete test`,
      text: `Delete the test: <b>${this.course.tests[index].ti}</b>?<br><br> You can restore this test by resetting the course. Changes will be permanent if you save the course.`,
      callback: (response: boolean) => {
        if (response) this.deleteTest(index)
      }
    })
  }

  deleteTest(index: number) {
    util.removeFromArray(this.course.tests, index)
  }
}
