







import {Component, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import firebase from 'firebase/app'
import 'firebase/firestore'
import dbHelper from "@/util/basic/dbHelper"
import params from "@/app/params"
import {ContentModel, ContentType} from "@/models/content/model_content"
import algoliasearch from "algoliasearch"
import {UnitModel} from '@/models/unit/model_unit'
import model_unit_preview from "@/models/unit/model_unit_preview"
import {ActionType} from "@/models/action/model_action"
import {McActionModel} from "@/models/action/model_action_mc"

@Component({
  components: {TextButton, Row}
})
export default class TestFirestore_code extends Vue {

  async run() {
    // count docs
    // await this.countDocsInCollection('curricula')
    // await this.countDocsInCollection('units')
    // await this.countDocsInCollection('recordings') // 4643
    // await this.countDocsInCollection('recordingsRaw') // 4392

    // copy
    // await this.copyAllDocs('recordingsRaw_v0_13', 'recordingsRaw')


    // modify units
    // let querySnapshot = await firebase.firestore().collection('units').get()
    // let units: Array<UnitModel> = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
    //
    // console.log('Units', units.length)
    //
    // let counter = 0
    // for (let unit of units) {
    //   let hasDataChanged = false
    //   for (let screen of unit.screens) {
    //
    //     if (screen.action && screen.action.level === undefined) {
    //       screen.action.level = ActionLevel.medium
    //       hasDataChanged = true
    //     }
    //
    //   }
    //
    //   if (hasDataChanged) {
    //     console.log(counter, unit.id, unit.title)
    //     await firebase.firestore().collection('units')
    //       .doc(unit.id).set(dbHelper.jsonToFirestoreJson(unit))
    //   }
    //
    //   counter++
    // }
    //
    // console.log('done')






    // console.log('units_v0_13:', json1.length)

    // let doc = await firebase.firestore().collection(params.firestore.units).doc('un_w7xsGCxtz3').get()
    // let unit: UnitModel = dbHelper.docToJson(doc)
    //
    // console.log(unit)

    // this.syncCollectionWithAlgolia()

    // this.buildUnitPreviews('curr_ypQs7ogjQg')


    // let startDate = new Date('Aug 1 2021 00:00:00 GMT+0100 (Central European Standard Time)')
    // let endDate = new Date('Sep 2 2021 00:00:00 GMT+0100 (Central European Standard Time)')
    //
    // let recordingsSnapshot = await firebase.firestore()
    //   .collection(params.firestore.recordingsRaw)
    //   .where('lastModifiedAt', '<', Timestamp.fromDate(endDate))
    //   .where('lastModifiedAt', '>', Timestamp.fromDate(startDate))
    //   // .limit(2000)
    //   .get()
    //
    // let recordings: Array<RecordingRawModel> = recordingsSnapshot.docs.map(doc => dbHelper.docToJson(doc))
    // console.log(recordings.length)
    //
    // let counter = 0
    // for (let i = 0; i < recordings.length; i++) {
    //   counter++
    //
    //   let r = recordings[i]
    //   let hasChanged = false
    //
    //   for (let s of r.sequences) {
    //     for (let path of s.paths) {
    //       if (path.type === PathType.spacer && (<SpacerPathModel>path).location) {
    //         hasChanged = true
    //         let p = <SpacerPathModel>path
    //         p.ad = {
    //           lo: p.location!,
    //           ii: p.index!,
    //         }
    //         p.h = p.height!
    //         p.t = p.time!
    //
    //         delete p.height
    //         delete p.time
    //         delete p.location
    //         delete p.index
    //       }
    //     }
    //   }
    //
    //   if (hasChanged) {
    //     console.log(counter, r.id, r.unitId)
    //     await firebase.firestore().collection(params.firestore.recordingsRaw)
    //       .doc(r.id).set(dbHelper.jsonToFirestoreJson(r))
    //   }
    // }
    //
    // console.log('done')

    // let startDate = new Date('Apr 1 2021 00:00:00 GMT+0100 (Central European Standard Time)')
    // // let endDate = new Date('Apr 1 2021 00:00:00 GMT+0100 (Central European Standard Time)')
    //
    // let recordingsSnapshot = await firebase.firestore()
    //   .collection(params.firestore.recordings)
    //   .where('lastModifiedAt', '<', Timestamp.fromDate(startDate))
    //   // .where('lastModifiedAt', '>', Timestamp.fromDate(endDate))
    //   .limit(3000)
    //   .get()
    //
    // let recordings: Array<RecordingModel> = recordingsSnapshot.docs.map(doc => dbHelper.docToJson(doc))
    // console.log(recordings.length)
    //
    // let counter = 0
    // for (let i = 0; i < recordings.length; i++) {
    //   counter++
    //
    //   let r = recordings[i]
    //   let hasChanged = false
    //
    //   for (let path of r.paths) {
    //     if (path.type === PathType.spacer && (<SpacerPathModel>path).location) {
    //       hasChanged = true
    //       let p = <SpacerPathModel>path
    //       p.ad = {
    //         lo: p.location!,
    //         ii: p.index!,
    //       }
    //       p.h = p.height!
    //       p.t = p.time!
    //
    //       delete p.height
    //       delete p.time
    //       delete p.location
    //       delete p.index
    //     }
    //   }
    //
    //   if (hasChanged) {
    //     console.log(counter, r.id, r.unitId)
    //     await firebase.firestore().collection(params.firestore.recordings)
    //       .doc(r.id).set(dbHelper.jsonToFirestoreJson(r))
    //   }
    // }
    //
    // console.log('done')
  }

  getAllUsedVideos(unit: UnitModel): Array<string> {
    let videoIds: Array<string> = []
    for (let screen of unit.screens) {

      // initial video
      if (screen.initialVideoId) {
        videoIds.push(screen.initialVideoId)
      }

      // content
      for (let item of screen.statics) {
        if (item.videoId) {
          videoIds.push(item.videoId)
        }
      }

      if (screen.action) {
        for (let response of screen.action.responses) {
          if (response.videoId) {
            videoIds.push(response.videoId)
          }
        }
      }

      // mc
      if (screen.action && screen.action.type === ActionType.mc) {
        for (let answer of (<McActionModel>screen.action).answers) {
          if (answer.videoId) {
            videoIds.push(answer.videoId)
          }
        }
      }

    }

    return videoIds
  }

  async copyAllDocs(sourceCol: string, targetCol: string) {
    console.log('COPY FROM ' + sourceCol + ' TO ' + targetCol)
    let db = firebase.firestore()

    // startAfter, startAt must be the same field as the field used in orderBy
    const query = firebase.firestore().collection(sourceCol).orderBy('id')

    let batchSize = 10

    let lastId = ''
    let totalCount = 0
    let c = 0
    for (let i = 0; i < 1000; i++) {
      let querySnapshot = lastId ? await query.startAfter(lastId).limit(batchSize).get() : await query.limit(batchSize).get()
      let numDocs = querySnapshot.size
      totalCount += numDocs

      console.log('FROM:', sourceCol, 'TO:', targetCol, 'count:', totalCount)


      let batch = db.batch()
      let json: Array<Object> = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
      for (let i = 0; i < json.length; i++) {
        // @ts-ignore
        batch.set(db.collection(targetCol).doc(json[i].id), dbHelper.jsonToFirestoreJson(json[i]))
        console.log('doc', c++)
      }
      await batch.commit()


      if (numDocs === batchSize) {
        lastId = querySnapshot.docs[numDocs-1].id
      } else {
        break
      }
    }

    console.log('done')
  }

  async countDocsInCollection(collection: string) {
    console.log('COUNT DOCS IN ' + collection)
    // startAfter, startAt must be the same field as the field used in orderBy
    const query = firebase.firestore().collection(collection).orderBy('id')

    let batchSize = 200

    let lastId = ''
    let totalCount = 0
    while (true) {
      let querySnapshot = lastId ? await query.startAfter(lastId).limit(batchSize).get() : await query.limit(batchSize).get()
      let numDocs = querySnapshot.size
      totalCount += numDocs

      if (numDocs === batchSize) {
        lastId = querySnapshot.docs[numDocs-1].id
      } else {
        break
      }
    }

    console.log('collection: ' + collection + ' -- num docs: ' + totalCount)
    return totalCount
  }









  async buildUnitPreviews(curriculumId: string) {
    let querySnapshot = await firebase.firestore()
      .collection(params.firestore.units)
      .where('curriculumId', '==', curriculumId)
      .get()

    let units: Array<UnitModel> = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
    console.log(units.length)

    let data = {}
    for (let i = 0; i < units.length; i++) {
      let unit = units[i]
      // @ts-ignore
      data[unit.id] = model_unit_preview.template_unit(unit)
    }

    console.log(data)
    await firebase.firestore().collection(params.firestore.unitPreviews)
      .doc(curriculumId).set(data)

    console.log('done')
  }

  async syncCollectionWithAlgolia() {
    let querySnapshot = await firebase.firestore().collection(params.firestore.units).limit(3000).get()

    let records = []
    for (let doc of querySnapshot.docs) {
      let data = doc.data()
      data.objectID = doc.id
      records.push(data)
    }

    console.log('records:', records.length)

    // TODO get key from https://www.algolia.com/apps/IKO82643G9/api-keys/restricted -> cloud_function_sync
    let client = algoliasearch('IKO82643G9', 'apiKey')
    let algoliaIndex = client.initIndex('units');

    for (let i = 0; i < records.length; i+=100) {
      console.log(i)
      try {
        await algoliaIndex.saveObjects(records.slice(i, Math.min(i+100, records.length)))
      } catch (error) {
        console.error('Error when importing contact into Algolia', error);
      }
    }
  }
}
