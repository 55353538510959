export interface PathModel {
  type: PathType
}

export enum PathType {
  pen = 'pen',
  flashlight = 'flashlight',
  spacer = 'spacer',
  slider = 'slider',
}

export enum PathColor {
  black = 'black',
  blue = 'blue',
  green = 'green',
  yellow = 'yellow',
  red = 'red',
}

export enum PathWidth {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface PenPathModel extends PathModel {
  color: PathColor
  width: PathWidth
  time: Array<number>
  x: Array<number>
  y: Array<number>
  deletionTime?: number
}

export interface FlashlightPathModel extends PathModel {
  color: PathColor
  width: PathWidth
  time: Array<number>
  x: Array<number>
  y: Array<number>
}

export interface SpacerPathModel extends PathModel {
  ad?: ItemAddress
  h: number
  t: number
  // location?: ItemLocation
  // index?: number
  // height?: number
  // time?: number
}

export interface SliderPathModel extends PathModel {
  ad: ItemAddress
  t: Array<number>
  v: Array<number>
}

export interface ItemAddress {
  lo: ItemLocation
  ii: number
  si?: number
}

export enum ItemLocation {
  content = 'static',
  action = 'action',
}