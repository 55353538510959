






























































import {Component, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import util from "@/util/util"
import {Route} from "vue-router"
import {eventbus} from "@/main"
import {AlertDialogType, EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import {RouteName} from "@/router/router"
import model_unit, {UnitModel, UnitStatus} from "@/models/unit/model_unit"
import ScreensMenu from "@/pages/unit_editor/ScreensMenu.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import Simulator from "@/components/simulator/Simulator.vue"
import UnitHeader from "@/pages/unit_editor/UnitHeader.vue"
import ScreensList from "@/pages/unit_editor/ScreensList.vue"
import store from "@/store/store"
import appState from "@/app/state/app_state"
import {RecordingModel} from "@/models/recording/model_recording"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import model_screen from "@/models/screen/model_screen"
import unit_content_validator from "@/pages/unit_editor/unit_content_validator"
import {ActionType} from "@/models/action/model_action"
import {McActionModel} from "@/models/action/model_action_mc"

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

@Component({
  components: {ScreenWidget, ScreensList, UnitHeader, Simulator, Container, ScreensMenu, Row, Column}
})
export default class UnitEditorPage extends Vue {
  navbarViewModel = new NavbarViewModel(true)
  sidePanelViewModel = new SidePanelViewModel(true)

  ignoreUnsavedChanges = false
  localUnit: UnitModel = model_unit.template_unit()
  localRecordings: Array<RecordingModel> = []

  activeScreenIndex = -1
  emptyScreen = model_screen.template_screen()

  get unsavedChanges() {
    let b = this.localUnit && !util.compare(
      {unit: this.localUnit, recordings: this.localRecordings},
      {unit: store.units.unit, recordings: store.units.recordings}
    )
    appState.unsavedUnitChanges = b
    return b
  }


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  async created() {
    // @ts-ignore
    this.initUnit(this.$route.params.id, this.$route.query.back)
  }

  beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    if (to.params.id !== from.params.id) {
      // @ts-ignore
      this.initUnit(to.params.id, to.query.back)
    }
    next()
  }

  beforeRouteLeave(to: Route, from: Route, next: () => void) {
    if (this.unsavedChanges && !this.ignoreUnsavedChanges) {

      eventbus.$emit(EventType.alertDialog, {
        alertType: AlertDialogType.save,
        callback: (response: boolean) => {
          if (response) {
            this.save(true)
          } else {
            next()
          }
        }
      })

    } else {
      next()
    }
  }

  async initUnit(unitId: string|null, backQuery: string|null) {
    window.scrollTo(0, 0)

    if (!unitId) {
      this.ignoreUnsavedChanges = true
      this.$router.push({name: RouteName.curriculum}).then()
      return
    }

    // update navbar
    eventbus.$emit(EventType.navbar, this.navbarViewModel)
    this.navbarViewModel.back = true

    if (backQuery) {
      // @ts-ignore
      this.navbarViewModel.backPath = backQuery
    } else {
      this.navbarViewModel.backRoute = {name: RouteName.curriculum}
    }

    this.navbarViewModel.resetCallback = this.reset
    this.navbarViewModel.saveCallback = this.save
    this.navbarViewModel.saveCloseCallback = () => {this.save(true)}

    // update sidePanel
    eventbus.$emit(EventType.sidePanel, this.sidePanelViewModel)
    this.sidePanelViewModel.json = {unit: this.localUnit, recordings: this.localRecordings}

    // load unit
    this.showLoading(true)

    await store.units.fetchUnit(unitId)
    this.localUnit = util.copy(store.units.unit)

    let title = '-'
    if (this.localUnit) {
      title = this.localUnit.title
      if (this.localUnit.refId) {
        title += ' - ' + this.localUnit.refId
      }
    }
    this.navbarViewModel.title = 'Unit: ' + title

    await store.units.fetchUnitRecordings(unitId)
    this.localRecordings = util.copyArray(store.units.recordings)

    this.showLoading(false)
  }


  /////////////////////////////////
  // Watch
  /////////////////////////////////
  appState = appState

  @Watch('appState.unitSavingRequest')
  unitSavingRequestChanged() {
    if (appState.unitSavingRequest) {
      appState.unitSavingRequest = false
      this.save(false, true)
    }
  }

  @Watch('unsavedChanges')
  unsavedChangesChanged() {
    this.navbarViewModel.showReset = this.unsavedChanges
    this.navbarViewModel.showSave = this.unsavedChanges
    this.navbarViewModel.showSaveClose = this.unsavedChanges
  }

  @Watch('localUnit', {deep: true})
  localUnitChanged() {
    appState.unitError = unit_content_validator.validate(this.localUnit)
    this.sidePanelViewModel.json = {unit: this.localUnit, recordings: this.localRecordings}
  }

  @Watch('localRecordings', {deep: true})
  localRecordingsChanged() {
    this.sidePanelViewModel.json = {unit: this.localUnit, recordings: this.localRecordings}
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  async save(close = false, autoSave = false, forceSaving = false, keepUnassignedRecordings = false, deleteUnassignedRecordings = false) {

    if (!forceSaving && store.units.unit && (store.units.unit.status === UnitStatus.waitForApproval || store.units.unit.status === UnitStatus.approved)) {
      eventbus.$emit(EventType.alertDialog, {
        alertType: AlertDialogType.warning,
        title: 'Changes might affect recordings',
        text: 'Any changes might affect existing recordings.<br><br>Do you want to continue anyway?',
        positiveButton: 'Save',
        callback: async (response: boolean) => {
          if (response) {
            // wait 10 ms, otherwise the callback of the second dialog (if there are unassigned recordings) won't work
            await new Promise(r => setTimeout(r, 10))
            this.save(close, autoSave, true)
          }
        }
      })
      return
    }

    if (appState.unitError?.errorMsg) {
      eventbus.$emit(EventType.alertDialog, {
        alertType: AlertDialogType.warning,
        title: autoSave ? 'Auto-saving failed' : 'Saving failed',
        text: 'Unit was not saved due to errors. Please fix them first.<br><br><b>Errors:</b><br>' + appState.unitError.errorMsg,
        negativeButtonVisible: false,
      })
      return
    }


    if (!autoSave && !keepUnassignedRecordings) {
      // remove unused videos
      let allVideoIds = this.getAllUsedVideos()
      let unusedRecordingIndices = []
      for (let i = 0; i < this.localRecordings.length; i++) {
        if (!allVideoIds.includes(this.localRecordings[i].id)) {
          unusedRecordingIndices.push(i)
        }
      }

      if (unusedRecordingIndices.length > 0) {
        if (!deleteUnassignedRecordings) {
          eventbus.$emit(EventType.alertDialog, {
            alertType: AlertDialogType.error,
            title: 'Unassigned recordings',
            text: 'There are <b>' + unusedRecordingIndices.length + '</b> unassigned recordings linked to this unit. <br><br>Do you want to <b>delete</b> them?',
            positiveButton: 'Delete',
            negativeButton: 'Keep',
            callback: (response: boolean) => {
              if (response) {
                this.save(close, autoSave, forceSaving, false, true)
              } else {
                this.save(close, autoSave, forceSaving, true)
              }
            }
          })
          return

        } else {
          for (let i = unusedRecordingIndices.length-1; i >= 0; i--) {
            util.removeFromArray(this.localRecordings, unusedRecordingIndices[i])
          }
        }
      }
    }


    this.showLoading(true)
    await store.units.updateUnit(this.localUnit, true, this.localRecordings)
    this.localUnit = util.copy(store.units.unit)
    this.localRecordings = util.copyArray(store.units.recordings)
    this.showLoading(false)

    if (close) {
      // @ts-ignore
      this.$router.push({path: (this.$route.query.back ?? '/')}).then()
    }
  }

  reset() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.warning,
      title: 'Discard changes',
      text: 'Current changes will be discarded.',
      positiveButton: 'Discard',
      callback: (response: boolean) => {
        if (response) {
          this.localUnit = util.copy(store.units.unit)
          this.localRecordings = util.copyArray(store.units.recordings)
        }
      }
    })
  }

  requestDeleting() {
    if (this.localUnit.screens.length === 0) {
      this.delete()
      return
    }

    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_high,
      title: 'Delete unit',
      text: `This will permanently delete this unit.<br><br>All <b>screens</b> and <b>recordings</b> will be deleted as well.`,
      confirmAnswer: 'Delete',
      callback: (response: boolean) => {
        if (response) {
          this.delete()
        }
      }
    })
  }

  async delete() {
    if (!this.localUnit) return

    this.showLoading(true)
    await store.units.deleteUnit(this.localUnit)
    this.showLoading(false)

    this.ignoreUnsavedChanges = true
    this.$router.push({name: RouteName.curriculum}).then()
  }

  getAllUsedVideos(): Array<string> {
    let videoIds: Array<string> = []
    for (let screen of this.localUnit.screens) {

      // initial video
      if (screen.initialVideoId) {
        videoIds.push(screen.initialVideoId)
      }

      // content
      for (let item of screen.statics) {
        if (item.videoId) {
          videoIds.push(item.videoId)
        }
      }

      // responses
      if (screen.action) {
        for (let response of screen.action.responses) {
          if (response.videoId) {
            videoIds.push(response.videoId)
          }
        }
      }

      // mc
      if (screen.action && screen.action.type === ActionType.mc) {
        for (let answer of (<McActionModel>screen.action).answers) {
          if (answer.videoId) {
            videoIds.push(answer.videoId)
          }
        }
      }

    }

    return videoIds
  }

  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
