





























import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import model_screen from "@/models/screen/model_screen"
import {ImageModel} from "@/models/content/model_image"
import ImageUploaderWithPreview from "@/components/basic/images/ImageUploaderWithPreview.vue"

@Component({
  components: {
    ImageUploaderWithPreview,
    RoundIconButton, Container, TextField, SpacerBox, TransitionEffect, Row, Column
  }
})
export default class ImageUploaderDialog extends Vue {
  @Prop({type: Object, required: true}) image!: ImageModel

  screen = model_screen.template_screen()
  isVisible = false


  /////////////////////////////////
  // Getters
  /////////////////////////////////


  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  created() {

  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  open() {
    this.isVisible = true
  }

  close() {
    this.isVisible = false
  }
}
