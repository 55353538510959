import firebase from 'firebase/app'
import 'firebase/auth'
import {UserModel, UserRole} from "@/models/user/model_user"
import router, {RouteName} from '@/router/router'
import params from "@/app/params"
import Timestamp = firebase.firestore.Timestamp


export default class AuthStore {
  _user?: UserModel = undefined
  _signedInStateKnown = false
  _insufficientPermissions = false

  init() {
    firebase.auth().onAuthStateChanged((user: firebase.User | null) => this._userStateListener(user)) // can't directly call the function because js sucks
  }

  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get userId(): string {
    return this._user ? this._user.id : ''
  }

  get userName(): string {
    return this._user ? (this._user.firstName + ' ' + this._user.lastName)  : ''
  }

  get userEmail(): string {
    return this._user ? this._user.email : ''
  }

  get userImage(): string {
    return this._user ? this._user.image : ''
  }

  get userRole(): UserRole {
    return this._user ? this._user.role : UserRole.user
  }

  get signedIn(): boolean {
    return !!this._user
  }

  get signedInStateKnown(): boolean {
    return this._signedInStateKnown
  }

  get insufficientPermissions(): boolean {
    return this._insufficientPermissions
  }


  /////////////////////////////////
  // Action
  /////////////////////////////////
  async signOut() {
    if (router.currentRoute.name !== RouteName.login) {
      router.push({name: RouteName.login}).then()
    }

    this._signedInStateKnown = false
    await firebase.auth().signOut()
  }

  async signIn() {
    this._signedInStateKnown = false
    await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
  }

  async _userStateListener(firebaseUser: firebase.User | null) {
    if (firebaseUser) {
      try {
        // load current MirandaUser and update data
        let documentSnapshot = await firebase.firestore()
          .collection(params.firestore.users)
          .doc(firebaseUser.uid)
          .get()

        if (documentSnapshot.exists) {
          this._user = (documentSnapshot.data() as UserModel)
          this._user.lastLogin = Timestamp.now()
          await firebase.firestore().collection(params.firestore.users).doc(this._user.id).set(this._user)
        }

        if (this._user && this._user.role === UserRole.admin) {

          // update state user
          this._insufficientPermissions = false
          this._signedInStateKnown = true

          // redirect
          let targetPath = router.currentRoute.query.redirect ? String(router.currentRoute.query.redirect) : '/'
          if (targetPath !== router.currentRoute.fullPath) {
            router.push({path: targetPath}).then()
          }

        } else {

          // if user role is not admin or tutor, user is signed out again
          this._user = undefined
          this._insufficientPermissions = true
          this.signOut()
        }

      } catch (e) {
        console.log(e)
        this._user = undefined
      }

    } else {
      this._signedInStateKnown = true
      this._user = undefined
    }
  }
}