













































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import ContentTypeSelector from "@/components/screen_editor/content/ContentTypeSelector.vue"
import ContentLineChartEditor from "@/components/screen_editor/content/chart/line_chart/ContentLineChartEditor.vue"
import ContentSpacerEditor from "@/components/screen_editor/content/spacer/ContentSpacerEditor.vue"
import ContentTableEditor from "@/components/screen_editor/content/table/ContentTableEditor.vue"
import ContentTextEditor from "@/components/screen_editor/content/text/ContentTextEditor.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import ContentImageEditor from "@/components/screen_editor/content/image/ContentImageEditor.vue"
import ContentBoxPlotEditor from "@/components/screen_editor/content/chart/box_plot/ContentBoxPlotEditor.vue"
import appState from "@/app/state/app_state"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import ContentPieChartEditor from "@/components/screen_editor/content/chart/pie_chart/ContentPieChartEditor.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import RecordingInfo from "@/components/recorder/RecordingInfo.vue"
import NewRecordingButton from "@/components/recorder/recorderWindow/NewRecordingButton.vue"
import PreviewPlayerWidget from "@/components/video/player/PreviewPlayerWidget.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import {UnitModel} from "@/models/unit/model_unit"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue";
import {ContentModel, ContentType} from "@/models/content/model_content"

@Component({
  components: {
    AdvancedSelect,
    PreviewPlayerWidget,
    NewRecordingButton,
    RecordingInfo,
    ContentPieChartEditor,
    AdvancedCheckbox,
    ContentBoxPlotEditor,
    RoundIconButton,
    ContentImageEditor, ContentTextEditor, ContentTableEditor, ContentSpacerEditor,
    ContentLineChartEditor, ContentTypeSelector, TextField,
    TextButton, ScreenWidget, Container, SpacerBox, Row, Column
  }
})
export default class ContentEditor extends Vue {
  @Prop({type: Object, required: true}) content!: ContentModel
  @Prop({type: Array, default: () => {return []}, required: false}) invalidTypes!: Array<string>
  @Prop({type: Boolean, default: false, required: false}) showPreview!: boolean
  @Prop({type: Boolean, default: false, required: false}) inlineText!: boolean
  @Prop({type: Boolean, default: false, required: false}) isStatic!: boolean

  @Prop({type: Boolean, default: false, required: false}) showRecordingButton?: boolean
  @Prop({type: Object, required: false}) unit?: UnitModel|undefined
  @Prop({type: Object, required: false}) screen?: ScreenModel|undefined
  @Prop({type: Array, required: false}) recordings?: Array<RecordingModel>|undefined

  contentTypes = ContentType
  contentVisible = true
  showVideo = false

  verticalPaddings = ['-16', '0', '16']

  get copiedContentAvailable() {
    return appState.copiedContent != null && JSON.stringify(this.content) !== appState.copiedContent
  }

  copyContent() {
    appState.copiedContent = JSON.stringify(this.content)
  }

  pasteContent() {
    if (appState.copiedContent) {
      this.$emit('onContentChanged',  JSON.parse(appState.copiedContent))
    }
  }

  /////////////////////////////////
  // Video
  /////////////////////////////////
  get recording(): RecordingModel|undefined {
    if (!this.recordings) return undefined

    return (this.content.videoId) ? this.recordings.find(r => r.id === this.content.videoId) : undefined
  }

  handleRecordingCompleted(videoId: string) {
    this.$set(this.content, 'videoId', videoId)
    appState.unitSavingRequest = true
  }

  ////////////////////////////////
  // Vertical padding
  ////////////////////////////////
  updateVerticalPadding(str: string) {
    this.$set(this.content, 'vp', parseInt(str))
  }
}
