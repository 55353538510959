import {ContentModel, ContentType} from "@/models/content/model_content"

export interface ImageModel extends ContentModel {
  imageType: ImageType
  url: string
  size: number
  fileType: string
  width: number
  height: number
  scaling: number
  showBorder?: boolean
  showPadding?: boolean
  source?: string
}

export enum ImageType {
  image = 'image',
  tenor = 'tenor',
  giphy = 'giphy',
  gfycat = 'gfycat',
}

export default {
  template_image(): ImageModel {
    return {
      type: ContentType.image,
      imageType: ImageType.image,
      url: '',
      size: 0,
      fileType: '',
      width: 0,
      height: 0,
      scaling: 1,
    }
  },
  template_gif(imageType?: ImageType): ImageModel {
    return {
      type: ContentType.gif,
      imageType: imageType ? imageType : ImageType.tenor,
      url: '',
      size: 0,
      fileType: '',
      width: 0,
      height: 0,
      scaling: 1,
    }
  },
}