



































































































import {Component, Prop, Vue} from "vue-property-decorator"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import {EventEntry, LogEvent} from "@/pages/fieldTestResults/model_log_events"
import JsonView from "@/components/fundamental/text/JsonView.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import firebase from 'firebase/app'

@Component({
  components: {SpacerBox, JsonView, TextButton, Column, RoundIconButton, Container, Row, TextField}
})
export default class LogEventDetails extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) logEvent!: LogEvent

  showEvents = false
  showResults = false
  showDetails = false

  get lastEvent() {
    return this.logEvent.events[this.logEvent.events.length-1]
  }

  getDateStr(time: firebase.firestore.Timestamp) {
    let date = time.toDate()
    let dateStr = `${date.getFullYear()}-${this.to2Digits(date.getMonth()+1)}-${this.to2Digits(date.getDate())}`;
    let timeStr = `${this.to2Digits(date.getHours())}:${this.to2Digits(date.getMinutes())}:${this.to2Digits(date.getSeconds())}.${this.to3Digits(date.getMilliseconds())}`;
    return dateStr + ' -- ' + timeStr
  }

  to2Digits(d: number): string {
    return d < 10 ? `0${d}` : `${d}`
  }

  to3Digits(d: number): string {
    return d < 10 ? `00${d}` : (d < 100 ? `0${d}` : `${d}`)
  }

  getBackgroundColor(event: EventEntry): string {
    switch (event.event) {
      case 'start_field_test': return 'green'
      case 'initial_test_completed': return 'green'
      case 'lesson_book': return 'green'
      case 'lesson_app': return 'green'
      case 'main_test_completed': return 'green'
      case 'personal_survey_completed': return 'green'
      case 'app_survey_completed': return 'green'
      case 'field_test_completed': return 'green'
      case 'cancel_field_test': return 'green'
    }

    switch (event.type) {
      case 'open_view': return 'blue'
      case 'system': return 'purple'
      case 'user_input': return 'yellow'
      case 'error': return 'red'
    }

    return ''
  }

  getHeaderBackgroundColor(event: EventEntry): string {
    switch (event.event) {
      case 'field_test_completed': return 'green'
    }

    return ''
  }

  copy(text: string) {
    navigator.clipboard.writeText(text).catch(error => {
      console.log('Clipboard error', error)
    })
  }
}
