























import {Component, Prop, Vue} from "vue-property-decorator"
import {RecordingModel} from "@/models/recording/model_recording"
import ListItemContainer from "@/pages/unit_editor/ListItemContainer.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import NewRecordingButton from "@/components/recorder/recorderWindow/NewRecordingButton.vue"
import RecordingInfo from "@/components/recorder/RecordingInfo.vue"
import VideoPlayerWidget from "@/components/video/player/VideoPlayerWidget.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import PreviewPlayerWidget from "@/components/video/player/PreviewPlayerWidget.vue"
import {PredefinedActionState} from "@/simulator/screen/sim_screen_communication"
import {ScreenModel} from "@/models/screen/model_screen"
import appState from "@/app/state/app_state"
import Column from "@/components/fundamental/layout/Column.vue"
import {ResponseModel, ResponseType} from "@/models/action/model_response"

@Component({
  components: {
    Column,
    PreviewPlayerWidget, SpacerBox, Row, VideoPlayerWidget, RecordingInfo, NewRecordingButton, TextField, ListItemContainer}
})
export default class ResponseVideo extends Vue {
  @Prop({type: String, required: true}) unitId!: string
  @Prop({required: true}) responseType!: ResponseType
  @Prop({type: Object, required: true}) response!: ResponseModel
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Array, required: true}) recordings!: Array<RecordingModel>
  @Prop({type: Boolean, default: false, required: false}) linkedExercise!: boolean

  get recording() {
    return this.response.videoId ? this.recordings.find(r => r.id === this.response.videoId) : undefined
  }

  get predefinedActionState() {
    if (this.responseType === ResponseType.wrong) {
      return PredefinedActionState.closedWithWrongAnswer

    } else if (this.responseType === ResponseType.correct) {
      return PredefinedActionState.closedWithCorrectAnswer

    } else {
      return null
    }
  }

  handleRecordingCompleted(videoId: string) {
    this.$set(this.response, 'videoId', videoId)
    appState.unitSavingRequest = true
  }
}
