













































import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"

@Component({
  components: {TextButton, InputField, Column, TransitionEffect, Checkbox, TextField, Row}
})
export default class AdvancedEditText extends Vue {
  @Prop({type: String, required: false, default: ''}) title!: string
  @Prop({type: String, required: false, default: ''}) info!: string
  @Prop({required: true}) value!: string | undefined
  @Prop({type: Boolean, default: false, required: false}) isNullable!: boolean
  @Prop({type: Number, default: 14, required: false}) fontSize!: number
  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) titleWidth!: number
  @Prop({type: Boolean, default: false, required: false}) titleBold!: boolean
  @Prop({type: Boolean, default: false, required: false}) longInputField!: boolean


  isDialogVisible = false
  newValue = ''

  get valueStr() {
    return this.value != undefined ? this.value : 'null'
  }

  get rowStyle() {
    let s = ''
    if (this.width > 0 ) {
      s += 'width:' + this.width + 'px;'
    }
    return s
  }

  get titleStyle() {
    let s = ''
    if (this.titleWidth > 0 ) {
      s += 'width:' + this.titleWidth + 'px;'
    }
    return s
  }

  open() {
    this.newValue = this.value ? this.value : ''
    this.isDialogVisible = true
  }

  save() {
    this.isDialogVisible = false
    this.$emit('input', this.newValue)
  }

  cancel() {
    this.isDialogVisible = false
  }

  resetValue() {
    this.isDialogVisible = false
    this.$emit('input', undefined)
  }
}
