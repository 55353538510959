






























































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import appState from "@/app/state/app_state"
import MirandaTopics from "@/pages/curriculum_viewer/courses/MirandaTopics.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import util from "@/util/util"
import {CourseModel} from "@/models/curriculum/model_course"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import FirestoreInfo from "@/components/basic/FirestoreInfo.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue";
import MirandaTests from "@/pages/curriculum_viewer/courses/MirandaTests.vue";
import MirandaFormulas from "@/pages/curriculum_viewer/courses/MirandaFormulas.vue";

@Component({
  components: {
    MirandaFormulas,
    MirandaTests,
    AdvancedSelect,
    FirestoreInfo, AdvancedEditText, IconButton,
    MirandaTopics, EditText, SelectField, TextButton, SpacerBox, Container, Row, TextField, Column}
})
export default class MirandaCourse extends Vue {
  @Prop({type: Object, required: true}) course!: CourseModel
  @Prop({required: true}) remoteCourse!: CourseModel | null
  @Prop({type: Boolean, required: true}) isSingleView!: boolean


  curriculumState = appState.curriculum


  /////////////////////////////////
  // Computed
  /////////////////////////////////
  get numCourseTopics() {
    if (!this.course) return 0

    return this.course.topics.length
  }

  get numCourseChapters() {
    if (!this.course) return 0

    let n = 0
    for (let topic of this.course.topics) {
      n += topic.ch.length
    }
    return n
  }

  get numCourseLessons() {
    if (!this.course) return 0

    let n = 0
    for (let topic of this.course.topics) {
      for (let chapter of topic.ch) {
        n += chapter.le.length
      }
    }
    return n
  }

  get numCourseUnits() {
    if (!this.course) return 0

    let n = 0
    for (let topic of this.course.topics) {
      for (let chapter of topic.ch) {
        for (let lesson of chapter.le) {
          n += lesson.un.length
        }
      }
    }
    return n
  }

  get numCourseTests() {
    if (!this.course) return 0

    return this.course.tests.length
  }

  get numTestLessons() {
    if (!this.course) return 0

    let n = 0
    for (let test of this.course.tests) {
      for (let chapter of test.ch) {
        n += chapter.le.length
      }
    }
    return n
  }

  get numTestUnits() {
    if (!this.course) return 0

    let n = 0
    for (let test of this.course.tests) {
      for (let chapter of test.ch) {
        for (let lesson of chapter.le) {
          n += lesson.un.length
        }
      }
    }
    return n
  }

  get unsavedChanges(): boolean {
    return !util.compare(this.course, this.remoteCourse)
  }


  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  mounted() {
    // @ts-ignore
    this.$refs.topicsContainer.getDiv().addEventListener('scroll', this.handleScrollEvent)

    this.$nextTick(() => {
      // @ts-ignore
      this.$refs.topicsContainer.getDiv().scrollTo(0, appState.curriculum.courseScrollPosition)
    })
  }

  destroy() {
    // @ts-ignore
    this.$refs.topicsContainer.getDiv().removeEventListener('scroll', this.handleScrollEvent)
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  handleScrollEvent() {
    // @ts-ignore
    appState.curriculum.courseScrollPosition = this.$refs.topicsContainer.getDiv().scrollTop
  }
}
