






import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "../../../fundamental/layout/Container.vue"
import PathDrawer from "@/components/simulator/screen/whiteboard/PathDrawer"
import {PathModel} from "@/models/recording/model_path"

@Component({
  components: {Container, Row, Column}
})
export default class Whiteboard extends Vue {
  @Prop({type: Number, required: true}) width!: number
  @Prop({type: Number, required: true}) height!: number
  @Prop({type: Array, required: true}) paths!: Array<PathModel>

  pathDrawer?: PathDrawer = undefined

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  mounted() {
    this.pathDrawer = new PathDrawer(<HTMLCanvasElement>this.$refs.canvas)
    this.draw()
  }

  @Watch('width')
  widthChanged() {
    this.$nextTick(() => {
      this.draw()
    })
  }

  @Watch('height')
  heightChanged() {
    this.$nextTick(() => {
      this.draw()
    })
  }

  @Watch('paths', {deep: true})
  pathsChanged() {
    this.draw()
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  draw() {
    if (this.pathDrawer) {
      this.pathDrawer.draw(this.paths)
    }
  }
}
