import {PathModel, ItemLocation} from "@/models/recording/model_path"
import {ScreenModel} from "@/models/screen/model_screen"

/////////////////////////////////
// Send
/////////////////////////////////
export enum SendType {
  screenData = 'screenData',
  pathsData = 'pathsData',
  predefinedActionState = 'predefinedActionState',
  scrollable = 'scrollable',
  drawingboard = 'drawingboard',
  borders = 'borders',
  linkedExercise = 'linkedExercise',
}

export interface PostSimulatorMessage {
  type: SendType
}

export interface PostSimulatorMessage_screenData extends PostSimulatorMessage {
  type: SendType.screenData
  screenData: ScreenModel
}

export interface PostSimulatorMessage_pathsData extends PostSimulatorMessage {
  type: SendType.pathsData
  pathsData: Array<PathModel>
}

export interface PostSimulatorMessage_predefinedActionState extends PostSimulatorMessage {
  type: SendType.predefinedActionState
  predefinedActionState: PredefinedActionState
}

export enum PredefinedActionState {
  closedWithCorrectAnswer = 'closedWithCorrectAnswer',
  closedWithWrongAnswer = 'closedWithWrongAnswer',
}

export interface PostSimulatorMessage_scrollable extends PostSimulatorMessage {
  type: SendType.scrollable
  scrollable: boolean
}

export interface PostSimulatorMessage_drawingboard extends PostSimulatorMessage {
  type: SendType.drawingboard
  drawingboard: boolean
}

export interface PostSimulatorMessage_borders extends PostSimulatorMessage {
  type: SendType.borders
  borders: boolean
}

export interface PostSimulatorMessage_linkedExercise extends PostSimulatorMessage {
  type: SendType.linkedExercise
  linked: boolean
}


/////////////////////////////////
// Receive
/////////////////////////////////
export enum ReceiveType {
  initCompleted = 'initCompleted',
  screenHeight = 'screenHeight',
  addSpacer = 'addSpacer',
  sliderChanged = 'sliderChanged',
  keyboardShortcut = 'keyboardShortcut',
}

export interface ReceivedSimulatorMessage {
  type: ReceiveType
  parentId: String
}

export interface ReceivedSimulatorMessage_initCompleted extends ReceivedSimulatorMessage {
  type: ReceiveType.initCompleted
  version: String
}

export interface ReceivedSimulatorMessage_screenHeight extends ReceivedSimulatorMessage {
  type: ReceiveType.screenHeight
  height: number
}

export interface ReceivedSimulatorMessage_addSpacer extends ReceivedSimulatorMessage {
  type: ReceiveType.addSpacer
  location: ItemLocation
  index: number
  positive: boolean
}

export interface ReceivedSimulatorMessage_sliderChanged extends ReceivedSimulatorMessage {
  type: ReceiveType.sliderChanged
  location: ItemLocation
  itemIndex: number
  sliderIndex: number
  value: number
}

export interface ReceivedSimulatorMessage_keyboardShortcut extends ReceivedSimulatorMessage {
  type: ReceiveType.keyboardShortcut
  key: String
}