








































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import util from "@/util/util"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import {PieChartModel} from '@/models/content/charts/model_pie_chart'
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import InlineColorSelector from "@/components/screen_editor/content/color/InlineColorSelector.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import {ColorModel} from "@/models/model_colors"
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue";

@Component({
  components: {
    BasicTextEditorDialog,
    AdvancedEditNumber,
    AdvancedEditText,
    InlineColorSelector,
    RoundIconButton,
    AdvancedScaling, AddButton,
    Container, TextField, SpacerBox, Row, Column
  }
})
export default class PieChartEditor extends Vue {
  @Prop({type: Object, required: true}) pieChart!: PieChartModel

  addValue() {
    this.pieChart.labels.push(this.pieChart.values.length + '')
    this.pieChart.values.push(1)
    this.pieChart.colors.push(ColorModel.cyan)
  }

  deleteValue(index: number) {
    util.removeFromArray(this.pieChart.labels, index)
    util.removeFromArray(this.pieChart.values, index)
    util.removeFromArray(this.pieChart.colors, index)
  }
}
