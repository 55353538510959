import firebase from 'firebase/app'
import 'firebase/firestore'
import {RecordingRawModel} from "@/models/recording/model_recording_raw"
import model_recording, {RecordingModel} from "@/models/recording/model_recording"
import dbHelper from "@/util/basic/dbHelper"
import params from "@/app/params"
import {
  PathModel,
  FlashlightPathModel,
  PenPathModel,
  SpacerPathModel,
  PathType,
  SliderPathModel
} from "@/models/recording/model_path"
import util from "@/util/util"
import mMath from "@/util/mMath"

export default class RecordingStore {

  init() {}

  async getRemoteRecording(recordingId: string): Promise<RecordingModel> {
    let doc = await firebase.firestore().collection(params.firestore.recordings).doc(recordingId).get()
    return dbHelper.docToJson(doc)
  }

  async updateRemoteRecordingRaw(recordingRaw: RecordingRawModel) {
    let db = firebase.firestore()
    let recording = this._buildRecordingFromRecordingRaw(recordingRaw)

    await db.collection(params.firestore.recordings).doc(recordingRaw.id).set(dbHelper.jsonToFirestoreJson(recording))
    await db.collection(params.firestore.recordingsRaw).doc(recordingRaw.id).set(dbHelper.jsonToFirestoreJson(recordingRaw))
  }

  async updateRemoteRecording(recording: RecordingModel) {
    let db = firebase.firestore()
    await db.collection(params.firestore.recordings).doc(recording.id).set(dbHelper.jsonToFirestoreJson(recording))
  }

  async deleteRemoteRecording(recordingId: string) {
    let db = firebase.firestore()
    let batch = db.batch()

    // delete recording and recordingRaw
    batch.delete(db.collection(params.firestore.recordings).doc(recordingId))
    batch.delete(db.collection(params.firestore.recordingsRaw).doc(recordingId))

    // commit
    await batch.commit()
  }

  async fetchOpenRecordings(): Promise<Array<RecordingModel>> {
    let querySnapshot = await firebase.firestore().collection(params.firestore.recordings)
      .where('videoEncodingRequired', '==', true).get()
    return querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
  }

  async resetVideoEncoding(recordingId: string) {
    let db = firebase.firestore()

    let doc = await db.collection(params.firestore.recordings).doc(recordingId).get()
    let recording = <RecordingModel>dbHelper.docToJson(doc)
    recording.videoEncodingRequired = true
    recording.url = ''
    recording.previewImages = []
    recording.videoFiles = []
    await db.collection(params.firestore.recordings).doc(recordingId).set(dbHelper.jsonToFirestoreJson(recording))

    doc = await db.collection(params.firestore.recordingsRaw).doc(recordingId).get()
    let recordingRaw = <RecordingRawModel>dbHelper.docToJson(doc)
    recordingRaw.videoEncodingRequired = true
    await db.collection(params.firestore.recordingsRaw).doc(recordingId).set(dbHelper.jsonToFirestoreJson(recordingRaw))
  }


  /////////////////////////////////
  // Helper
  /////////////////////////////////
  _buildRecordingFromRecordingRaw(recordingRaw: RecordingRawModel) {

    let recording = model_recording.template()
    recording.id = recordingRaw.id
    recording.createdBy = recordingRaw.createdBy
    recording.createdAt = recordingRaw.createdAt

    recording.screenVersion = recordingRaw.screenVersion
    recording.unitId = recordingRaw.unitId
    recording.screenId = recordingRaw.screenId

    recording.duration = recordingRaw.duration
    recording.videoEncodingRequired = recordingRaw.videoEncodingRequired
    recording.language = 'de-DE' // TODO: should be set dynamically later

    let offsetTime = 0

    for (let sequence of recordingRaw.sequences) {
      for (let p of sequence.paths) {
        let path = <PathModel>util.copy(p)

        switch (path.type) {
          case PathType.pen:

            // update deletion time
            let p_pen = (<PenPathModel>path)
            if (p_pen.deletionTime) {
              p_pen.deletionTime += mMath.round(offsetTime - sequence.startTime, 3)
            }

            // update path time
            for (let i = 0; i < p_pen.time.length; i++) {
              p_pen.time[i] += mMath.round(offsetTime - sequence.startTime, 3)
            }
            break

          case PathType.flashlight:

            // update path time
            let p_flashlight = (<FlashlightPathModel>path)
            for (let i = 0; i < p_flashlight.time.length; i++) {
              p_flashlight.time[i] += mMath.round(offsetTime - sequence.startTime, 3)
            }

            break

          case PathType.spacer:
            let p_spacer = (<SpacerPathModel>path)
            p_spacer.t += mMath.round(offsetTime - sequence.startTime, 3)

            break

          case PathType.slider:

            // update path time
            let p_slider = (<SliderPathModel>path)
            for (let i = 0; i < p_slider.t.length; i++) {
              p_slider.t[i] += mMath.round(offsetTime - sequence.startTime, 3)
            }

            break

        }

        recording.paths.push(path)
      }
      offsetTime = sequence.stopTime - sequence.startTime
    }

    return recording
  }
}