






















































import {Component, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {RecordingRawModel} from "@/models/recording/model_recording_raw"
import {eventbus} from '@/main'
import {AlertDialogType, EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RecorderControl from "@/components/recorder/RecorderControl.vue"
import Drawingboard from "@/components/recorder/drawingboard/Drawingboard.vue"
import {PredefinedActionState} from "@/simulator/screen/sim_screen_communication"
import store from "@/store/store"
import {ScreenModel} from "@/models/screen/model_screen"

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

@Component({
  components: {Drawingboard, RecorderControl, Container, SpacerBox, TextField, Row, Column}
})
export default class RecorderWindow extends Vue {
  navbarViewModel = new NavbarViewModel(true)
  sidePanelViewModel = new SidePanelViewModel(true)

  unitId = ''
  screenId = ''
  screenVersion = ''
  recordingId = ''
  predefinedActionState: PredefinedActionState | null = null

  screen: ScreenModel | null = null
  linkedExercise = false

  drawingEnabled = true
  recordingRaw: RecordingRawModel | null = null


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    // update navbar
    eventbus.$emit(EventType.navbar, this.navbarViewModel)
    this.navbarViewModel.title = 'Recorder Window'
    this.navbarViewModel.back = true
    this.navbarViewModel.showBackArrow = false
    this.navbarViewModel.saveCallback = this.save
    this.navbarViewModel.cancelCallback = this.cancel


    // update sidePanel
    eventbus.$emit(EventType.sidePanel, this.sidePanelViewModel)
    this.sidePanelViewModel.json = this.recordingRaw

    store.recorderWindow.subscribeToRecorderCommunication()
      .then(() => {
      })
      .catch((error) => {
        eventbus.$emit(EventType.alertDialog, {alertType: AlertDialogType.oops, oopsMsg: error})
      })
  }


  /////////////////////////////////
  // Watch
  /////////////////////////////////
  recorderWindowStore = store.recorderWindow // is required for @Watch('recorderWindowStore.request')

  @Watch('recorderWindowStore.request')
  async requestChanged() {
    let request = store.recorderWindow.request

    if (request && request.unitId) {
      this.unitId = request.unitId
      this.screenId = request.screenId
      this.recordingId = request.recordingId
      this.predefinedActionState = request.predefinedActionState

      this.showLoading(true)
      await store.units.fetchUnit(this.unitId)
      this.showLoading(false)

      let newScreen = store.units.unit?.screens.find(s => s.id === this.screenId)
      if (newScreen) {
        this.screen = newScreen
      }
      this.linkedExercise = store.units.unit?.linkedExercise ?? false

    } else {
      this.reset()
    }
  }

  @Watch('unitId')
  unitIdChanged() {
    this.navbarViewModel.showCancel = !!this.unitId
  }

  @Watch('recordingRaw')
  recordingRawChanged() {
    this.navbarViewModel.showSave = (!!this.recordingRaw && !!this.recordingRaw.id)
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  started() {
  }

  stopped(recordingRaw: RecordingRawModel) {
    this.recordingRaw = recordingRaw

    this.recordingRaw.unitId = this.unitId
    this.recordingRaw.screenId = this.screenId
    this.recordingRaw.screenVersion = this.screenVersion

    this.sidePanelViewModel.json = this.recordingRaw

    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.info,
      title: 'Save recording?',
      text: 'Save the new recording and go back.',
      positiveButton: 'Save',
      negativeButton: 'Not now',
      callback: (response: boolean) => {
        if (response) this.save()
      }
    })
  }

  async save() {
    if (!this.recordingRaw) return

    try {
      this.showLoading(true)

      // 1. save recording and recordingRaw
      await store.recordings.updateRemoteRecordingRaw(this.recordingRaw)

      // 2. submit response to main window
      await store.recorderWindow.submitResponse({recordingId: this.recordingId})

      // 3. reset
      this.reset()

    } catch (e) {

      this.showLoading(false)
      eventbus.$emit(EventType.alertDialog, {alertType: AlertDialogType.oops, oopsMsg: e})
    }
  }

  async cancel() {
    try {
      await store.recorderWindow.submitResponse({recordingId: ''})
      this.reset()
    } catch (e) {
      this.showLoading(false)
      eventbus.$emit(EventType.alertDialog, {alertType: AlertDialogType.oops, oopsMsg: e})
    }
  }

  reset() {
    this.showLoading(false)

    this.unitId = ''
    this.screenId = ''
    this.recordingId = ''
    this.recordingRaw = null
  }

  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
