











































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {CurriculumModel} from "@/models/curriculum/model_curriculum"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import OfficialTopic from "@/pages/curriculum_viewer/official/OfficialTopic.vue"
import UnassignedUnits from "@/pages/curriculum_viewer/official/UnassignedUnits.vue"
import FirestoreInfo from "@/components/basic/FirestoreInfo.vue";
import util from "@/util/util";
import store from "@/store/store";
import IconButton from "@/components/fundamental/buttons/IconButton.vue";
import appState from "@/app/state/app_state";

@Component({
  components: {
    IconButton,
    FirestoreInfo,
    UnassignedUnits, OfficialTopic, TextButton, SpacerBox, Container, Row, TextField, Column}
})
export default class CurriculumTable extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Boolean, required: true}) isSingleView!: boolean


  ////////////////////////////////
  // Computed
  ////////////////////////////////
  get curriculumTitle() {
    return util.capitalizeFirstLetter(this.curriculum.co) + ' - ' + this.curriculum.sc
  }

  get numOfficialTopics() {
    return this.curriculum.to.length
  }

  get numOfficialChapters() {
    let n = 0
    for (let topic of this.curriculum.to) {
      n += topic.ch ? topic.ch.length : 0
    }
    return n
  }

  get numOfficialSections() {
    let n = 0
    for (let topic of this.curriculum.to) {
      if (!topic.ch) continue
      for (let chapter of topic.ch) {
        n += chapter.se ? chapter.se.length : 0
      }
    }
    return n
  }

  get numUnits() {
    let j = store.curriculum.getUnitPreviewJson(this.curriculum.id)
    return j ? Object.getOwnPropertyNames(j).length : 0
  }


  ////////////////////////////////
  // Life cycle
  ////////////////////////////////
  mounted() {
    // @ts-ignore
    this.$refs.topicsContainer.getDiv().addEventListener('scroll', this.handleScrollEvent)

    this.$nextTick(() => {
      // @ts-ignore
      this.$refs.topicsContainer.getDiv().scrollTo(0, appState.curriculum.officialCurriculumScrollPosition)
    })
  }

  destroy() {
    // @ts-ignore
    this.$refs.topicsContainer.getDiv().removeEventListener('scroll', this.handleScrollEvent)
  }

  handleScrollEvent() {
    // @ts-ignore
    appState.curriculum.officialCurriculumScrollPosition = this.$refs.topicsContainer.getDiv().scrollTop
  }
}
