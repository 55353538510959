import {FirestoreModel} from "@/models/model_firestore"
import model_firestore from "@/models/model_firestore"
import {ScreenModel} from "@/models/screen/model_screen"
import util from "@/util/util"


export enum UnitStatus {
  empty,
  dev,
  archive,
  waitForReview,
  reviewed,
  waitForApproval,
  approved,
}

export enum UnitStatusLabels {
  empty = 'Empty',
  dev = 'Development',
  archive = 'Archive',
  waitForReview = 'Waiting for Review',
  reviewed = 'Reviewed',
  waitForApproval = 'Waiting for Approval',
  approved = 'Approved',
}

export enum UnitType {
  theory,
  action,
  faq,
  test,
  definition,
  formula
}

export enum UnitLevel {
  easy,
  medium,
  difficult,
  sick,
}

export interface UnitModel extends FirestoreModel {
  type: UnitType
  status: UnitStatus
  pending?: boolean
  error?: boolean
  level?: UnitLevel
  rank: number
  searchTerms?: string
  notes?: string
  curriculumId: string
  refId?: string
  title: string
  linkedExercise?: boolean
  screens: Array<ScreenModel>
  minViTi: number
  maxViTi?: number
}

export default {
  template_unit(curriculumId = ''): UnitModel {
    let unit = {
      ...model_firestore.template('un'),

      curriculumId: curriculumId,
      type: UnitType.theory,
      status: UnitStatus.empty,
      rank: 0,

      title: 'new unit',
      screens: [],
      minViTi: 0,
    }

    this.updateRank(unit)
    return unit
  },
  copyUnit(unit: UnitModel): UnitModel {
    let u: UnitModel = {
      ...model_firestore.template('un'),

      curriculumId: unit.curriculumId,
      type: unit.type,
      status: unit.status,
      rank: unit.rank,

      title: unit.title + ' - copy',
      screens: util.copyArray(unit.screens),
      minViTi: unit.minViTi,
    }

    if (unit.level) {
      u.level = unit.level
    }

    if (unit.refId) {
      u.refId = unit.refId
    }

    if (unit.searchTerms) {
      u.searchTerms = unit.searchTerms
    }

    if (unit.notes) {
      u.notes = unit.notes
    }

    if (unit.maxViTi) {
      u.maxViTi = unit.maxViTi
    }

    if (unit.error) {
      u.error = unit.error
    }

    return u
  },
  updateRank(unit: UnitModel) {

    switch (unit.type) {
      case UnitType.theory:
        unit.rank = 30
        break

      case UnitType.action:
        unit.rank = 10
        break

      case UnitType.faq:
        unit.rank = 20
        break

      case UnitType.test:
        unit.rank = 0
        break

      case UnitType.definition:
        unit.rank = 60
        break

      case UnitType.formula:
        unit.rank = 40
        break
    }
  }
}