








































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {UnitModel} from "@/models/unit/model_unit"
import {RecordingModel} from "@/models/recording/model_recording"
import mMath from "@/util/mMath"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import {ResponseType} from "@/models/action/model_response"
import {ActionType} from "@/models/action/model_action"
import {McActionModel} from "@/models/action/model_action_mc"

@Component({
  components: {TransitionEffect, Container, RoundIconButton, TextField, Row, Column}
})
export default class UnitExtendedInfo extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({type: Array, default: undefined, required: false}) recordings!: Array<RecordingModel>

  isDialogVisible = false
  dialogTitle = ''
  dialogRecordings: Array<RecordingModel> = []

  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get numActions(): number {
    let c = 0
    for (let screen of this.unit.screens) {
      if (screen.action) {
       c++
      }
    }
    return c
  }


  get unassignedVideos(): Array<RecordingModel> {
    let videos: Array<RecordingModel> = []

    let initialVideos = this.initialVideos
    let contentVideos = this.contentVideos
    let tipVideos = this.tipVideos
    let correctVideos = this.correctVideos
    let wrongVideos = this.wrongVideos
    let mcAnswerVideos = this.mcAnswerVideos

    for (let r of this.recordings) {
      if (!initialVideos.includes(r) && !tipVideos.includes(r) && !contentVideos.includes(r) &&
        !correctVideos.includes(r) && !wrongVideos.includes(r) && !mcAnswerVideos.includes(r)) {
        videos.push(r)
      }
    }
    return videos
  }


  get initialVideos(): Array<RecordingModel> {
    let videos: Array<RecordingModel> = []
    for (let screen of this.unit.screens) {
      if (screen.initialVideoId) {
        let recording = this.recordings.find(e => e.id === screen.initialVideoId)
        if (recording) videos.push(recording)
      }
    }
    return videos
  }

  get contentVideos(): Array<RecordingModel> {
    let videos: Array<RecordingModel> = []
    for (let screen of this.unit.screens) {
      for (let item of screen.statics) {
        if (item.videoId) {
          let recording = this.recordings.find(e => e.id === item.videoId)
          if (recording) videos.push(recording)
        }
      }
    }
    return videos
  }

  get tipVideos(): Array<RecordingModel> {
    let videos: Array<RecordingModel> = []
    for (let screen of this.unit.screens) {
      if (screen.action) {
        for (let response of screen.action.responses) {
          if (response.type === ResponseType.tip && response.videoId) {
            let recording = this.recordings.find(e => e.id === response.videoId)
            if (recording) videos.push(recording)
          }
        }
      }
    }
    return videos
  }

  get correctVideos(): Array<RecordingModel> {
    let videos: Array<RecordingModel> = []
    for (let screen of this.unit.screens) {
      if (screen.action) {
        for (let response of screen.action.responses) {
          if (response.type === ResponseType.correct && response.videoId) {
            let recording = this.recordings.find(e => e.id === response.videoId)
            if (recording) videos.push(recording)
          }
        }
      }
    }
    return videos
  }

  get wrongVideos(): Array<RecordingModel> {
    let videos: Array<RecordingModel> = []
    for (let screen of this.unit.screens) {
      if (screen.action) {
        for (let response of screen.action.responses) {
          if (response.type === ResponseType.wrong && response.videoId) {
            let recording = this.recordings.find(e => e.id === response.videoId)
            if (recording) videos.push(recording)
          }
        }
      }
    }
    return videos
  }

  get mcAnswerVideos(): Array<RecordingModel> {
    let videos: Array<RecordingModel> = []
    for (let screen of this.unit.screens) {
      if (screen.action && screen.action.type === ActionType.mc) {
        for (let answer of (<McActionModel>screen.action).answers) {
          if (answer.videoId) {
            let recording = this.recordings.find(e => e.id === answer.videoId)
            if (recording) videos.push(recording)
          }
        }
      }
    }
    return videos
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  getTotalDuration(recordings: Array<RecordingModel>): number {
    let t = 0
    for (let r of recordings) {
      t += r.duration
    }
    return mMath.round(t, 2)
  }

  getAverageDuration(recordings: Array<RecordingModel>): number {
    return mMath.round(recordings.length > 0 ? this.getTotalDuration(recordings)/recordings.length : 0, 2)
  }

  openInfoDialog(title: string, recordings: Array<RecordingModel>) {
    this.dialogTitle = title
    this.isDialogVisible = true
    this.dialogRecordings = recordings
  }

  copy(text: string) {
    navigator.clipboard.writeText(text).catch(error => {
      console.log('Clipboard error', error)
    })
  }
}
