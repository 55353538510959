import model_firestore, {FirestoreModel} from "@/models/model_firestore"
import {PathModel} from "@/models/recording/model_path"
import {CameraDetailsJson} from "@/components/recorder/camera/EosHandler"

export interface RecordingRawModel extends FirestoreModel {
  screenVersion: string
  unitId: string
  screenId: string
  startTime: number
  duration: number
  videoEncodingRequired: boolean
  sequences: Array<SequenceModel>
}

export interface SequenceModel {
  startTime: number
  stopTime: number
  duration: number
  filename: string
  paths: Array<PathModel>
  cameraStartingTime: number
  cameraStoppingTime: number
  cameraDetails?: CameraDetailsJson
}

export default {
  template_recordingRaw(): RecordingRawModel {
    return {
      ...model_firestore.template('re'),
      screenVersion: '',
      unitId: '',
      screenId: '',
      startTime: Date.now() / 1000,
      duration: 0,
      videoEncodingRequired: false,
      sequences: [this.template_sequence()],
    }
  },

  template_sequence(): SequenceModel {
    return {
      startTime: 0,
      stopTime: 0,
      duration: 0,
      filename: '',
      paths: [],
      cameraStartingTime: 0,
      cameraStoppingTime: 0,
      cameraDetails: undefined
    }
  },
}