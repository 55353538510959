










import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "../../fundamental/animations/TransitionEffect.vue"
import TextField from "../../fundamental/text/TextField.vue"
import Container from "../../fundamental/layout/Container.vue"
import {VideoController, VideoControllerValue} from "../controller/VideoController"

@Component({
  components: {Container, TextField, TransitionEffect, Row, Column}
})
export default class Subtitles extends Vue {
  @Prop({default: null, required: false}) videoController!: VideoController | null
  @Prop({type: Boolean, required: true}) visible!: boolean

  callbackId = -1
  subtitleText = ''

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    this.videoControllerChanged()
  }

  beforeDestroy() {
    if (this.videoController && this.callbackId >= 0) {
      this.videoController.removeValueCallback(this.callbackId)
    }
  }

  @Watch('videoController')
  videoControllerChanged() {
    if (this.videoController && this.callbackId < 0) {
      this.callbackId = this.videoController.addValueCallback((v: VideoControllerValue) => {

        let subtitle = v.recording.subtitles.find(subtitle => {
          return (subtitle.start <= v.time && subtitle.stop > v.time)
        })
        this.subtitleText = subtitle ? subtitle.text : ''

      })
    }
  }
}
