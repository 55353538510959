import model_chart_params, {ChartParamsModel} from "@/models/content/charts/model_chart_params"
import model_chart_box, {ChartBoxModel} from "@/models/content/charts/model_chart_box"
import model_chart_function, {ChartFunctionModel} from "@/models/content/charts/model_chart_function"
import {ChartPointModel} from "@/models/content/charts/model_chart_point"
import {ChartTextModel} from "@/models/content/charts/model_chart_text"
import {ChartVectorModel} from "@/models/content/charts/model_chart_vector"
import {ChartRectModel} from "@/models/content/charts/model_chart_rect"
import {ContentModel, ContentType} from "@/models/content/model_content"


export interface XyChartModel extends ContentModel {
  chartParams: ChartParamsModel
  boxes?: Array<ChartBoxModel>
  functions?: Array<ChartFunctionModel>
  points?: Array<ChartPointModel>
  rects?: Array<ChartRectModel>
  texts?: Array<ChartTextModel>
  vectors?: Array<ChartVectorModel>
  xyRatio: number
  scaling: number
  drawable?: boolean
  source?: string
}

export default {
  template_lineChart(): XyChartModel {
    return {
      type: ContentType.xyChart,
      chartParams: model_chart_params.template_lineChart(),
      functions: [model_chart_function.template()],
      xyRatio: 1,
      scaling: 1,
    }
  },
  template_lineChart_small(): XyChartModel {
    return {
      type: ContentType.xyChart,
      chartParams: model_chart_params.template_lineChart_small(),
      functions: [model_chart_function.template_small()],
      xyRatio: 1,
      scaling: 1,
    }
  },
  template_dragBoxAction(): XyChartModel {
    return {
      type: ContentType.boxPlot,
      chartParams: model_chart_params.template_boxPlot(),
      xyRatio: 1,
      scaling: 1,
    }
  },
  template_boxPlot(): XyChartModel {
    return {
      type: ContentType.boxPlot,
      chartParams: model_chart_params.template_boxPlot(),
      boxes: [model_chart_box.template()],
      xyRatio: 0.4,
      scaling: 1,
    }
  },
}