















































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import ChartFunctionEditor from "@/components/screen_editor/content/chart/line_chart/ChartFunctionEditor.vue"
import util from "@/util/util"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import ChartParamsEditor from "@/components/screen_editor/content/chart/ChartParamsEditor.vue"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import ChartTextEditor from "@/components/screen_editor/content/chart/ChartTextEditor.vue"
import ChartPointEditor from "@/components/screen_editor/content/chart/line_chart/ChartPointEditor.vue"
import {XyChartModel} from "@/models/content/charts/model_xy_chart"
import model_chart_function from "@/models/content/charts/model_chart_function"
import model_chart_point from "@/models/content/charts/model_chart_point"
import model_chart_text from "@/models/content/charts/model_chart_text"
import {SizeModel} from "@/models/math/model_size"
import ChartVectorEditor from "@/components/screen_editor/content/chart/line_chart/ChartVectorEditor.vue"
import model_chart_vector from "@/models/content/charts/model_chart_vector"
import ChartRectEditor from "@/components/screen_editor/content/chart/line_chart/ChartRectEditor.vue"
import model_chart_rect from '@/models/content/charts/model_chart_rect'
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue";
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue";

@Component({
  components: {
    BasicTextEditorDialog,
    AdvancedCheckbox,
    ChartRectEditor,
    ChartVectorEditor,
    ChartPointEditor, ChartTextEditor,
    AdvancedScaling, ChartParamsEditor, AddButton, ChartFunctionEditor,
    Container, TextField, SpacerBox, Row, Column
  }
})
export default class LineChartEditor extends Vue {
  @Prop({type: Object, required: true}) xyChart!: XyChartModel
  @Prop({type: Object, default: null, required: false}) fieldSize!: SizeModel|null


  get smallContent(): boolean {
    return (
      this.fieldSize != null && this.fieldSize.width != null && this.fieldSize.width < 200 ||
      this.fieldSize != null && this.fieldSize.maxWidth != null && this.fieldSize.maxWidth < 200
    )
  }


  addFunction() {
    if (!this.xyChart.functions) {
      this.$set(this.xyChart, 'functions', [])
    }

    this.xyChart.functions!.push(this.smallContent ? model_chart_function.template_small() : model_chart_function.template())
  }

  deleteFunction(index: number) {
    if (!this.xyChart.functions) return

    util.removeFromArray(this.xyChart.functions, index)
  }

  addPoint() {
    if (!this.xyChart.points) {
      this.$set(this.xyChart, 'points', [])
    }

    this.xyChart.points!.push(model_chart_point.template())
  }

  deletePoint(index: number) {
    if (!this.xyChart.points) return

    util.removeFromArray(this.xyChart.points, index)
  }

  addRect() {
    if (!this.xyChart.rects) {
      this.$set(this.xyChart, 'rects', [])
    }

    this.xyChart.rects!.push(
      model_chart_rect.template(this.xyChart.rects!.length > 0 ? this.xyChart.rects![this.xyChart.rects!.length-1].color : undefined)
    )
  }

  deleteRect(index: number) {
    if (!this.xyChart.rects) return

    util.removeFromArray(this.xyChart.rects, index)
  }

  useColorForAllRects(index: number) {
    let color = this.xyChart.rects![index].color
    for (let rect of this.xyChart.rects!) {
      rect.color = color;
      rect.borderColor = color;
    }
  }

  addText() {
    if (!this.xyChart.texts) {
      this.$set(this.xyChart, 'texts', [])
    }

    this.xyChart.texts!.push(model_chart_text.template())
  }

  deleteText(index: number) {
    if (!this.xyChart.texts) return

    util.removeFromArray(this.xyChart.texts, index)
  }

  addVector() {
    if (!this.xyChart.vectors) {
      this.$set(this.xyChart, 'vectors', [])
    }

    this.xyChart.vectors!.push(model_chart_vector.template())
  }

  deleteVector(index: number) {
    if (!this.xyChart.vectors) return

    util.removeFromArray(this.xyChart.vectors, index)
  }
}
