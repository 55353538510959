


























import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Link from "@/components/fundamental/text/Link.vue"
import NavigationElement from "@/test_area/NavigationElement.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {RouteName} from "@/router/router"

@Component({
  components: {SpacerBox, NavigationElement, Link, TextField, Row, Column}
})
export default class FirestoreOverview extends Vue {
  routeNames = RouteName
}
