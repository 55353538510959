import model_firestore, {FirestoreModel} from "@/models/model_firestore"
import {PathModel} from "@/models/recording/model_path"
import {SubtitleModel, SubtitlesRawModel} from "@/models/recording/model_subtitle"
import util from "@/util/util"

export interface RecordingModel extends FirestoreModel {
  screenVersion: string
  unitId: string
  screenId: string
  duration: number
  width: number
  height: number
  videoEncodingRequired: boolean
  videoEncodedAt: string
  videoEncodedBy: string
  language: string
  url: string
  previewImages: Array<PreviewImageModel>
  videoFiles: Array<VideoFileModel>
  paths: Array<PathModel>
  subtitlesRaw: SubtitlesRawModel
  subtitles: Array<SubtitleModel>
  keyframes: Array<KeyframeModel>

  playerZoomFactor?: number
  playerAlignment?: string
}

export interface PreviewImageModel {
  width: number
  height: number
  url: string
}

export interface VideoFileModel {
  width: number
  height: number
  size: number
  url: string
}

export interface KeyframeModel {
  time: number
  fullscreen: boolean
}

export default {
  template(): RecordingModel {
    return {
      ...model_firestore.template('re'),
      screenVersion: '',
      unitId: '',
      screenId: '',
      duration: 0,
      width: 0,
      height: 0,
      videoEncodingRequired: false,
      videoEncodedAt: '',
      videoEncodedBy: '',
      language: '',
      url: '',
      previewImages: [],
      videoFiles: [],
      paths: [],
      subtitlesRaw: {},
      subtitles: [],
      keyframes: [],
    }
  },
  copyRecording(recording: RecordingModel, newUnitId: string) {
    return {
      ...model_firestore.template('re'),
      screenVersion: recording.screenVersion,
      unitId: newUnitId,
      screenId: recording.screenId,
      duration: recording.duration,
      width: recording.width,
      height: recording.height,
      videoEncodingRequired: recording.videoEncodingRequired,
      videoEncodedAt: recording.videoEncodedAt,
      videoEncodedBy: recording.videoEncodedBy,
      language: recording.language,
      url: recording.url,
      previewImages: util.copyArray(recording.previewImages),
      videoFiles: util.copyArray(recording.videoFiles),
      paths: util.copyArray(recording.paths),
      subtitlesRaw: util.copy(recording.subtitlesRaw),
      subtitles: util.copyArray(recording.subtitles),
      keyframes: util.copyArray(recording.keyframes),
    }
  }
}
