import firebase from 'firebase/app'
import 'firebase/firestore'
import model_unit, {UnitModel, UnitStatus} from "@/models/unit/model_unit"
import util from "@/util/util"
import store from "./store"
import dbHelper from '@/util/basic/dbHelper'
import model_recording, {RecordingModel} from "@/models/recording/model_recording"
import params from "@/app/params"
import {CurriculumModel} from "@/models/curriculum/model_curriculum"
import {CourseModel} from "@/models/curriculum/model_course";
import {ResponseType} from "@/models/action/model_response"
import {ActionType} from "@/models/action/model_action"
import {McActionModel} from "@/models/action/model_action_mc"


export default class UnitsStore {
  _unit?: UnitModel = undefined
  _unitUnsubscribe?: any = undefined

  _recordingsUnitId = ''
  _recordings: Array<RecordingModel> = []
  _recordingsUnsubscribe?: any = undefined


  init() {}


  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get unit(): UnitModel | undefined {
    return this._unit
  }

  get recordings(): Array<RecordingModel> {
    return this._recordings
  }


  /////////////////////////////////
  // Fetch unit & recordings
  /////////////////////////////////
  fetchUnit(unitId: string) {
    return new Promise<void>((resolve, reject) => {
      if (this._unitUnsubscribe && this._unit && this._unit.id === unitId) {
        resolve()
        return
      }

      if (this._unitUnsubscribe) this._unitUnsubscribe()

      this._unitUnsubscribe = firebase.firestore().collection(params.firestore.units).doc(unitId)
        .onSnapshot(
          (doc) => {
            this._unit = dbHelper.docToJson(doc)
            resolve()
          },
          error => reject(error)
        )
    })
  }

  fetchUnitRecordings(unitId: string) {
    return new Promise<void>((resolve, reject) => {
      if (this._recordingsUnsubscribe && this._recordingsUnitId === unitId) {
        resolve()
        return
      }

      if (this._recordingsUnsubscribe) this._recordingsUnsubscribe()

      this._recordingsUnitId = unitId

      this._recordingsUnsubscribe = firebase.firestore().collection(params.firestore.recordings).where('unitId', '==', unitId)
        .onSnapshot(
          querySnapshot => {
            this._recordings = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
            resolve()
          },
          error => reject(error)
        )
    })
  }


  /////////////////////////////////
  // Add, update, delete
  /////////////////////////////////
  async addUnit(curriculum: CurriculumModel, subsectionId: string) {
    let db = firebase.firestore()
    let batch = db.batch()

    // add unit
    let newUnit = model_unit.template_unit(curriculum.id)
    batch.set(db.collection(params.firestore.units).doc(newUnit.id), dbHelper.jsonToFirestoreJson(newUnit))

    // update curriculum
    let topicIndex = 0, chapterIndex = 0, sectionIndex = 0, subsectionIndex = 0
    topicsLoop: for (let i = 0; i < curriculum.to.length; i++) {
      for (let j = 0; j < curriculum.to[i].ch.length; j++) {
        for (let k = 0; k < curriculum.to[i].ch[j].se.length; k++) {
          for (let m = 0; m < curriculum.to[i].ch[j].se[k].su.length; m++) {
            if (curriculum.to[i].ch[j].se[k].su[m].id === subsectionId) {
              topicIndex = i
              chapterIndex = j
              sectionIndex = k
              subsectionIndex = m
              break topicsLoop
            }
          }
        }
      }
    }

    curriculum.to[topicIndex].ch[chapterIndex].se[sectionIndex].su[subsectionIndex].un.unshift(newUnit.id)
    batch.set(db.collection(params.firestore.curricula).doc(curriculum.id), dbHelper.jsonToFirestoreJson(curriculum))

    // update UnitPreviewList
    store.curriculum.updateUnitLocally(newUnit)

    // commit
    await batch.commit()
  }

  async copyUnit(unit: UnitModel) {
    let db = firebase.firestore()
    let batch = db.batch()

    // copy unit
    let copiedUnit = model_unit.copyUnit(unit)


    // copy recordings
    await this.fetchUnitRecordings(unit.id)

    for (let recording of this._recordings) {
      let newRecording = model_recording.copyRecording(recording, copiedUnit.id)
      batch.set(db.collection(params.firestore.recordings).doc(newRecording.id), dbHelper.jsonToFirestoreJson(newRecording))

      // replace recording id in screen
      for (let screen of copiedUnit.screens) {
        if (screen.initialVideoId === recording.id) {
          screen.initialVideoId = newRecording.id
        } else if (screen.action) {
          for (let response of screen.action.responses) {
            if (response.videoId === recording.id) {
              response.videoId = newRecording.id
            }
          }
        }
      }

      await new Promise(r => setTimeout(r, 10)) // wait 10 ms, to avoid that the same id is used for multiple recordings
    }

    // update UnitPreviewList
    store.curriculum.updateUnitLocally(copiedUnit)

    // save unit
    batch.set(db.collection(params.firestore.units).doc(copiedUnit.id), dbHelper.jsonToFirestoreJson(copiedUnit))

    // commit
    await batch.commit()
  }

  async updateUnit(unit: UnitModel, deepUpdate = false, recordings: Array<RecordingModel> = []) {
    let localUnit = unit
    let localRecordings = recordings

    let remoteUnit = this._unit
    let remoteRecordings = this._recordings

    if (!localUnit || !remoteUnit || localUnit.id !== remoteUnit.id) {
      throw(Error('updateUnit() - ids of localUnit and remoteUnit do not match'))
    }


    // update status
    if (localUnit.status === UnitStatus.empty && localUnit.screens.length > 0) {
      localUnit.status = UnitStatus.dev

    } else if (localUnit.status !== UnitStatus.empty && localUnit.screens.length === 0) {
      localUnit.status = UnitStatus.empty
    }


    // update rank
    model_unit.updateRank(localUnit)


    // update duration
    if (deepUpdate) {
      this._updateRuntimes(unit, recordings)
    }


    let db = firebase.firestore()
    let batch = db.batch()

    // update unit
    batch.set(db.collection(params.firestore.units).doc(localUnit.id), dbHelper.jsonToFirestoreJson(localUnit))

    // update recordings
    if (deepUpdate) {
      // remove recordings associated with deleted screens
      let deletedRecordings = []
      for (let remoteScreen of remoteUnit.screens) {

        if (localUnit.screens.findIndex(s => s.id === remoteScreen.id) === -1) {
          // delete all recordings linked to that screen
          for (let remoteRecording of remoteRecordings) {
            if (remoteRecording.screenId === remoteScreen.id) {
              deletedRecordings.push(remoteRecording.id)
              batch.delete(db.collection(params.firestore.recordings).doc(remoteRecording.id))
              batch.delete(db.collection(params.firestore.recordingsRaw).doc(remoteRecording.id))
            }
          }
        }
      }

      // remove deleted recordings
      for (let remoteRecording of remoteRecordings) {
        if (localRecordings.findIndex(r => r.id === remoteRecording.id) === -1) {
          batch.delete(db.collection(params.firestore.recordings).doc(remoteRecording.id))
          batch.delete(db.collection(params.firestore.recordingsRaw).doc(remoteRecording.id))
        }
      }

      // update recordings
      for (let localRecording of localRecordings) {
        if (deletedRecordings.findIndex(id => id === localRecording.id) > -1) continue

        if (!util.compare(localRecording, remoteRecordings.find(s => s.id === localRecording.id))) {
          batch.set(db.collection(params.firestore.recordings).doc(localRecording.id), dbHelper.jsonToFirestoreJson(localRecording))
        }
      }
    }

    // update UnitPreviewList locally
    store.curriculum.updateUnitLocally(localUnit)

    // commit
    await batch.commit()
  }

  async deleteUnit(unit: UnitModel) {
    if (this._unit && this._unit.id === unit.id) {
      if (this._unitUnsubscribe) this._unitUnsubscribe()
      if (this._recordingsUnsubscribe) this._recordingsUnsubscribe()
    }

    let db = firebase.firestore()
    let batch = db.batch()

    // delete unit
    batch.delete(db.collection(params.firestore.units).doc(unit.id))

    // remove unit from curriculum
    let curriculum = this.removeUnitFromCurriculum(unit)
    if (curriculum) {
      batch.set(db.collection(params.firestore.curricula).doc(curriculum.id), dbHelper.jsonToFirestoreJson(curriculum))
    }

    // remove unit from courses
    let courses = this.removeUnitFromCourses(unit)
    if (courses) {
      for (let c of courses) {
        batch.set(db.collection(params.firestore.courses).doc(c.id), dbHelper.jsonToFirestoreJson(c))
      }
    }

    // delete recordings
    let querySnapshot = await db.collection(params.firestore.recordings).where('unitId', '==', unit.id).get()
    querySnapshot.forEach(doc => {
      batch.delete(db.collection(params.firestore.recordings).doc(doc.id))
      batch.delete(db.collection(params.firestore.recordingsRaw).doc(doc.id))
    })

    // update UnitPreviewList locally
    store.curriculum.deleteUnitLocally(unit)

    // commit
    await batch.commit()
  }


  /////////////////////////////////
  // Helper
  /////////////////////////////////
  _updateRuntimes(unit: UnitModel, recordings: Array<RecordingModel>) {
    let minVideoRuntime = 0
    let maxVideoRuntime = 0

    for (let screen of unit.screens) {

      // initial video
      if (screen.initialVideoId) {
        let recording = recordings.find(e => e.id === screen.initialVideoId)
        minVideoRuntime += recording ? recording.duration : 0
        maxVideoRuntime += recording ? recording.duration : 0
      }

      // content videos
      for (let item of screen.statics) {
        if (!item.videoId) continue
        let recording = recordings.find(e => e.id === item.videoId)
        if (!recording) continue

        minVideoRuntime += recording.duration
        maxVideoRuntime += recording.duration
      }

      // action
      if (screen.action) {

        // responses
        for (let response of screen.action!.responses) {

          if (!response.videoId) continue
          let recording = recordings.find(e => e.id === response.videoId)
          if (recording == null) continue

          // correct
          if (response.type == ResponseType.correct) {
            minVideoRuntime += recording.duration
            if (screen.action.type === ActionType.slideUnlock) {
              maxVideoRuntime += recording.duration
            }

          } else {
            // tip, wrong
            maxVideoRuntime += recording.duration
          }
        }

        // mc answers - we assume that only 2 answers are wrong (on average)
        if (screen.action.type === ActionType.mc) {
          let t = 0
          let count = 0
          for (let answer of (screen.action as McActionModel).answers) {

            if (answer.videoId == null) continue
            let recording = recordings.find(e => e.id == answer.videoId)
            if (recording == null) continue

            t += recording.duration
            count++
          }

          maxVideoRuntime += count <= 2 ? t : t/count*2
        }
      }
    }

    unit.minViTi = Math.round(minVideoRuntime)
    if (maxVideoRuntime > minVideoRuntime) {
      unit.maxViTi = Math.round(maxVideoRuntime)
    } else {
      delete unit.maxViTi;
    }
  }

  removeUnitFromCurriculum(unit: UnitModel): CurriculumModel | null {
    let curriculum = store.curriculum.getRemoteCurriculum(unit.curriculumId)

    if (curriculum) {
      topicLoop: for (let topic of curriculum.to) {
        for (let chapter of topic.ch) {
          for (let section of chapter.se) {
            for (let sub of section.su) {
              for (let i = 0; i < sub.un.length; i++) {
                if (sub.un[i] === unit.id) {
                  util.removeFromArray(sub.un, i)
                  break topicLoop
                }
              }
            }
          }
        }
      }
    }

    return curriculum
  }

  removeUnitFromCourses(unit: UnitModel): Array<CourseModel> | null {
    let courses = store.courses.remoteCourses

    if (courses) {
      for (let course of courses) {

        for (let topic of course.topics) {
          for (let chapter of topic.ch) {
            for (let lesson of chapter.le) {
              for (let i = 0; i < lesson.un.length; i++) {
                if (lesson.un[i] === unit.id) {
                  util.removeFromArray(lesson.un, i)
                }
              }
            }
          }
        }

        for (let test of course.tests) {
          for (let chapter of test.ch) {
            for (let lesson of chapter.le) {
              for (let i = 0; i < lesson.un.length; i++) {
                if (lesson.un[i] === unit.id) {
                  util.removeFromArray(lesson.un, i)
                }
              }
            }
          }
        }

      }
    }

    return courses
  }
}