


















































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import ScreenEditorHeader from "@/components/screen_editor/ScreenEditorHeader.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import StaticEditor from "@/components/screen_editor/static/StaticEditor.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ActionEditor from "@/components/screen_editor/action/ActionEditor.vue"
import InitialVideoEditor from "@/components/screen_editor/initial_video/InitialVideoEditor.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import appState from "@/app/state/app_state"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue";
import {UnitModel} from "@/models/unit/model_unit";
import store from "@/store/store";
import FindUnitDialog from "@/components/unit/FindUnitDialog.vue";
import AddButton from "@/components/fundamental/buttons/AddButton.vue";
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue";

@Component({
  components: {
    RoundIconButton,
    AddButton,
    FindUnitDialog,
    AdvancedEditText,
    AdvancedEditNumber, TextField, InputField,
    InitialVideoEditor, ActionEditor, SpacerBox, StaticEditor, TransitionEffect, ScreenEditorHeader, Row, Column
  }
})
export default class ScreenEditor extends Vue {
  @Prop({type: String, required: true}) curriculumId!: string
  @Prop({type: Object, required: false}) unit!: UnitModel
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Array, required: true}) recordings!: Array<RecordingModel>
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Boolean, required: true}) showContent!: boolean

  unitError = appState.unitError


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  appState = appState

  @Watch('appState.unitError')
  unitErrorChanged() {
    this.unitError = appState.unitError
  }


  ////////////////////////////////
  // Related Units
  ////////////////////////////////
  addRelatedUnit(unit: UnitModel) {
    if (!this.screen.relatedUnits) {
      this.$set(this.screen, 'relatedUnits', [])
    }

    this.screen.relatedUnits?.push(unit.id)
  }

  removeRelatedUnit(index: number) {
    if (this.screen.relatedUnits) {
      this.$delete(this.screen.relatedUnits!, index)

      if (this.screen.relatedUnits.length == 0) {
        this.$delete(this.screen, 'relatedUnits')
      }
    }
  }

  openRelatedUnit(unitId: string) {
    this.$router.push({path: '/unit-editor/' + unitId, query: {back: this.$route.fullPath}})
  }

  getUnitPreviewTitle(unitId: string): string {
    return store.curriculum.getUnitPreview(unitId)?.ti ?? '--'
  }

  getUnitPreviewNumScreens(unitId: string): number {
    return store.curriculum.getUnitPreview(unitId)?.sc ?? -1
  }
}
