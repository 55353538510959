export default {
  firestore: {
    units: 'units',
    curricula: 'curricula',
    courses: 'courses',
    unitPreviews: 'unitPreviews',
    recordingsRaw: 'recordingsRaw',
    recordings: 'recordings',
    sounds: 'sounds',

    users: 'users',
    logs2: 'logs2',             // users/{userId}/logs2
    recorder: 'recorder',       // users/{userId}/recorder
    devSettings: 'devSettings',  // users/{userId}/devSettings
    unitToApp: 'unitToApp',     // users/{userId}/devSettings/unitToApp
  },

  awsPresignUrl: 'https://5c3pzliy26.execute-api.eu-west-1.amazonaws.com/dev',

  algoliaAppId: 'IKO82643G9',
  algoliaSearchApiKey: '8f2b6d0af03fad6b9d2c44dc65d2d7f1',
}