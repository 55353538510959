import {CurriculumModel} from "@/models/curriculum/model_curriculum"
import { CourseModel } from '@/models/curriculum/model_course'

export class CurriculumState {

  curriculumId = ''
  newCurriculum = false
  scrollPosition = 0
  officialCurriculumScrollPosition = 0

  courseScrollPosition = 0
  courseDomainTypes = ['topics', 'formulas', 'tests']
  courseDomainType = 'topics'

  officialCurriculumVisible = true
  coursesVisible = true

  officialTopicsVisible: Map<string, boolean> = new Map()
  officialChaptersVisible: Map<string, boolean> = new Map()
  officialSectionsVisible: Map<string, boolean> = new Map()
  officialSubsectionsVisible: Map<string, boolean> = new Map()

  mirandaCourseId = ''
  unsavedCourseChanges = false
  mirandaTopicsVisible: Map<string, boolean> = new Map()
  mirandaChaptersVisible: Map<string, boolean> = new Map()
  mirandaTestsVisible: Map<string, boolean> = new Map()
  mirandaTestChaptersVisible: Map<string, boolean> = new Map()

  curriculumChanged(curriculum: CurriculumModel|null) {
    if (!curriculum) {
      this.reset()
      return
    }

    // update curriculum id
    if (curriculum.id !== this.curriculumId) {
      this.reset()
      this.curriculumId = curriculum.id
    }

    // update official curriculum
    for (let topic of curriculum.to) {
      if (!this.officialTopicsVisible.has(topic.id)) {
        this.officialTopicsVisible.set(topic.id, false)
      }

      for (let chapter of topic.ch) {
        if (!this.officialChaptersVisible.has(chapter.id)) {
          this.officialChaptersVisible.set(chapter.id, true)
        }

        for (let section of chapter.se) {
          if (!this.officialSectionsVisible.has(section.id)) {
            this.officialSectionsVisible.set(section.id, true)
          }

          for (let sub of section.su) {
            if (!this.officialSubsectionsVisible.has(sub.id)) {
              this.officialSubsectionsVisible.set(sub.id, true)
            }
          }
        }
      }
    }
  }

  courseChanged(course: CourseModel|null) {
    if (!course) {
      this.resetCourse()
      return
    }

    // update course id
    if (course.id !== this.mirandaCourseId) {
      this.resetCourse()
      this.mirandaCourseId = course.id
    }

    // update miranda course
    for (let topic of course.topics) {
      if (!this.mirandaTopicsVisible.has(topic.id)) {
        this.mirandaTopicsVisible.set(topic.id, false)
      }

      for (let chapter of topic.ch) {
        if (!this.mirandaChaptersVisible.has(chapter.id)) {
          this.mirandaChaptersVisible.set(chapter.id, true)
        }
      }
    }

    for (let test of course.tests) {
      if (!this.mirandaTestsVisible.has(test.id)) {
        this.mirandaTestsVisible.set(test.id, false)
      }

      for (let chapter of test.ch) {
        if (!this.mirandaTestChaptersVisible.has(chapter.id)) {
          this.mirandaTestChaptersVisible.set(chapter.id, true)
        }
      }
    }
  }

  reset() {
    this.curriculumId = ''
    this.scrollPosition = 0
    this.officialCurriculumScrollPosition = 0
    this.newCurriculum = false
    this.officialTopicsVisible = new Map()
    this.officialChaptersVisible = new Map()
    this.officialSectionsVisible = new Map()
    this.resetCourse()
  }

  resetCourse() {
    this.courseScrollPosition = 0
    this.mirandaCourseId = ''
    this.unsavedCourseChanges = false
    this.mirandaTopicsVisible = new Map()
    this.mirandaChaptersVisible = new Map()
    this.mirandaTestsVisible = new Map()
    this.mirandaTestChaptersVisible = new Map()
  }
}