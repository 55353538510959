
































































































































































































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import McAnswer from "@/components/screen_editor/action/mc/McAnswer.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import util from "@/util/util"
import TextEditor from "@/components/screen_editor/content/text/TextEditor.vue"
import InlineTextEditor from "@/components/screen_editor/content/text/InlineTextEditor.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import ContentTextEditor from "@/components/screen_editor/content/text/ContentTextEditor.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import model_action_input, {
  InputActionModel,
  InputType,
  InputTypeLabels,
  VectorType
} from "@/models/action/model_action_input";
import model_text from "@/models/content/model_text";


@Component({
  components: {
    Container,
    ContentTextEditor,
    AdvancedCheckbox,
    BasicTextEditorDialog,
    RoundIconButton,
    AdvancedEditText,
    InlineTextEditor,
    TextEditor,
    AdvancedSelect, AdvancedEditNumber,
    SpacerBox, McAnswer, EditNumber, SelectField, TextField, ActionSectionHeader, AddButton, Draggable, Row, Column}
})
export default class InputActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: InputActionModel

  inputTypes = InputType
  inputTypeValues = util.enumToArray(InputType)
  inputTypeLabels = util.enumToArray(InputTypeLabels)
  vectorTypeValues = util.enumToArray(VectorType)


  showSingleTargetInput(index: number): boolean {
    let type = this.action.inputs[index].type
    return type === InputType.term ||
      type === InputType.equation ||
      type === InputType.time ||
      type === InputType.interval
  }

  showMultipleTargetInputs(index: number): boolean {
    let type = this.action.inputs[index].type
    return type === InputType.vector ||
      type === InputType.ci ||
      type === InputType.coordinates
  }

  showGlobalTolerance(index: number): boolean {
    let type = this.action.inputs[index].type
    return type === InputType.term ||
      type === InputType.equation ||
      type === InputType.time ||
      type === InputType.ci ||
      type === InputType.coordinates ||
      type === InputType.paramEq ||
      type === InputType.vector
  }

  showVariables(index: number): boolean {
    let type = this.action.inputs[index].type
    return type === InputType.term ||
      type === InputType.equation ||
      type === InputType.vector ||
      type === InputType.ci ||
      type === InputType.coordinates ||
      type === InputType.paramEq
  }

  showFunctions(index: number): boolean {
    let type = this.action.inputs[index].type
    return type === InputType.term ||
      type === InputType.equation ||
      type === InputType.vector ||
      type === InputType.ci ||
      type === InputType.coordinates ||
      type === InputType.paramEq
  }



  updateInputType(index: number, v: InputType) {
    if (this.action.inputs[index].type === v) return

    switch (v) {
      case InputType.term:
        this.$set(this.action.inputs, index, model_action_input.template_term())
        break
      case InputType.equation:
        this.$set(this.action.inputs, index, model_action_input.template_equation())
        break
      case InputType.time:
        this.$set(this.action.inputs, index, model_action_input.template_time())
        break
      case InputType.interval:
        this.$set(this.action.inputs, index, model_action_input.template_interval())
        break
      case InputType.dropDown:
        this.$set(this.action.inputs, index, model_action_input.template_dropDown())
        break
      case InputType.vector:
        this.$set(this.action.inputs, index, model_action_input.template_vector())
        break
      case InputType.ci:
        this.$set(this.action.inputs, index, model_action_input.template_ci())
        break
      case InputType.coordinates:
        this.$set(this.action.inputs, index, model_action_input.template_coordinates())
        break
      case InputType.paramEq:
        this.$set(this.action.inputs, index, model_action_input.template_paramEq())
        break
      case InputType.slideUnlock:
        this.$set(this.action.inputs, index, model_action_input.template_slideUnlock())
        break
    }
  }

  addInputItem() {
    this.action.inputs.push(model_action_input.template_term())
  }

  deleteInputItem(index: number) {
    this.$delete(this.action.inputs, index)
  }


  toggleText(index: number) {
    if (this.action.inputs[index].text) {
      this.$delete(this.action.inputs[index], 'text')
    } else {
      this.$set(this.action.inputs[index], 'text', model_text.template())
    }
  }


  addTargetInput(inputIndex: number) {
    this.action.inputs[inputIndex].targetInputs!.push('')
  }

  deleteTargetInput(inputIndex: number, targetIndex: number) {
    this.$delete(this.action.inputs[inputIndex].targetInputs!, targetIndex)
  }



  addSelection(inputIndex: number) {
    this.action.inputs[inputIndex].selection!.push(''+(this.action.inputs[inputIndex].selection!.length+1))
  }

  deleteSelection(inputIndex: number, selectionIndex: number) {
    this.$delete(this.action.inputs[inputIndex].selection!, selectionIndex)
  }



  addTargetPointAndVector(inputIndex: number) {
    this.action.inputs[inputIndex].targetInputs!.push('0')
    this.action.inputs[inputIndex].targetInputs!.push('0')
  }

  deleteTargetPointAndVector(inputIndex: number, rowIndex: number) {
    this.$delete(this.action.inputs[inputIndex].targetInputs!, 2*rowIndex+1)
    this.$delete(this.action.inputs[inputIndex].targetInputs!, 2*rowIndex)
  }



  updatePointToleranceVariableName(inputIndex: number, name: string) {
    if (!this.action.inputs[inputIndex].pointTolerance && name) {
      this.$set(this.action.inputs[inputIndex], 'pointTolerance', model_action_input.template_pointTolerance())

    } else if (this.action.inputs[inputIndex].pointTolerance && !name) {
      this.$delete(this.action.inputs[inputIndex], 'pointTolerance')

    } else {
      this.action.inputs[inputIndex].pointTolerance!.name = name
    }
  }

  addPointToleranceValue(inputIndex: number) {
    this.action.inputs[inputIndex].pointTolerance!.values.push(0)
    this.action.inputs[inputIndex].pointTolerance!.tolerances.push(0.1)
  }

  deletePointToleranceValue(inputIndex: number, pointIndex: number) {
    this.$delete(this.action.inputs[inputIndex].pointTolerance!.values, pointIndex)
    this.$delete(this.action.inputs[inputIndex].pointTolerance!.tolerances, pointIndex)
  }

  deletePointTolerance(inputIndex: number) {
    this.$delete(this.action.inputs[inputIndex], 'pointTolerance')
  }



  addVariable(inputIndex: number) {
    if (this.action.inputs[inputIndex].variables === undefined) {
      this.$set(this.action.inputs[inputIndex], 'variables', [])
    }

    this.action.inputs[inputIndex].variables!.push(model_action_input.template_variable())
  }

  deleteVariable(inputIndex: number, variableIndex: number) {
    this.$delete(this.action.inputs[inputIndex].variables!, variableIndex)
  }



  addFunction(inputIndex: number) {
    if (this.action.inputs[inputIndex].functions === undefined) {
      this.$set(this.action.inputs[inputIndex], 'functions', [])
    }

    this.action.inputs[inputIndex].functions!.push('f(x):=x')
  }

  deleteFunction(inputIndex: number, funcIndex: number) {
    this.$delete(this.action.inputs[inputIndex].functions!, funcIndex)
  }
}
