import {ColorModel} from "@/models/model_colors";
import {ContentModel, ContentType} from "@/models/content/model_content"

export interface PieChartModel extends ContentModel {
  labels: Array<string>
  values: Array<number>
  colors: Array<ColorModel>
  scaling: number
  diameter: number
  source?: string
}

export default {
  template(): PieChartModel {
    return {
      type: ContentType.pieChart,
      labels: ['1', '2', '3'],
      values: [1, 1, 1],
      colors: [ColorModel.teal, ColorModel.yellow, ColorModel.blue],
      scaling: 1,
      diameter: 1,
    }
  },
}