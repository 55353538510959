import model_firestore, {FirestoreModel} from "@/models/model_firestore"
import util from "@/util/util"
import {Country, CurriculumModel} from './model_curriculum'
import {CourseIcon} from "@/models/model_icon"

export interface CourseModel extends FirestoreModel {
  title: string
  curriculumId: string
  language: string
  country: Country
  school: string
  topics: Array<TopicModel>
  tests: Array<TestModel>
  formulas: FormulasModel
}

export interface TestModel {
  id: string
  ti: string
  ch: Array<ChapterModel>
}

export interface FormulasModel {
  id: string
  ch: Array<FormulaChapterModel>
}

export interface FormulaChapterModel {
  id: string
  ti: string
  un: Array<string>
}

export interface TopicModel {
  id: string
  ti: string
  st?: string
  ch: Array<ChapterModel>
  ic: CourseIcon
}

export interface ChapterModel {
  id: string
  ti: string
  st?: string
  le: Array<LessonModel>
}

export interface LessonModel {
  id: string
  ti: string
  st?: string
  sh?: string
  lo?: string
  ta?:  string
  ty: LessonType
  un: Array<string>
}

export enum LessonType {
  theory,
  exercise,
  faq,
  definitions
}

export default {
  template_course(curriculum: CurriculumModel): CourseModel {
    return {
      ...model_firestore.template('co'),
      title: 'new course',
      curriculumId: curriculum.id,
      language: '',
      country: curriculum.co,
      school: curriculum.sc,
      topics: [],
      tests: [],
      formulas: this.template_formulas(),
    }
  },
  template_test(): TestModel {
    return {
      id: util.getId('te'),
      ti: '',
      ch: []
    }
  },
  template_formulas(): FormulasModel {
    return {
      id: util.getId('fo'),
      ch: []
    }
  },
  template_formulaChapter(): FormulaChapterModel {
    return {
      id: util.getId('fc'),
      ti: '',
      un: []
    }
  },
  template_topic(): TopicModel {
    return {
      id: util.getId('to'),
      ti: '',
      ic: CourseIcon.algebra,
      ch: []
    }
  },
  template_chapter(): ChapterModel {
    return {
      id: util.getId('ch'),
      ti: '',
      le: [],
    }
  },
  template_lesson(): LessonModel {
    return {
      id: util.getId('le'),
      ti: '',
      ty: LessonType.theory,
      un: []
    }
  },
}