



























import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import MirandaChapter from "@/pages/curriculum_viewer/courses/MirandaChapter.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import util from "@/util/util"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import model_course, {TopicModel} from "@/models/curriculum/model_course";

@Component({
  components: {AddButton, Draggable, MirandaChapter, TextButton, SpacerBox, Container, Row, Column}
})
export default class MirandaChapters extends Vue {
  @Prop({type: Object, required: true}) topic!: TopicModel
  @Prop({type: Boolean, required: false, default: false}) isTestSection!: boolean

  addChapter() {
    this.topic.ch.push(model_course.template_chapter())
  }

  requestDeletingChapter(index: number) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete chapter`,
      text: `Delete chapter: <b>${this.topic.ch[index].ti}</b>?<br><br> You can restore this chapter by resetting the course. Changes will be permanent if you save the course.`,
      callback: (response: boolean) => {
        if (response) this.deleteChapter(index)
      }
    })
  }

  deleteChapter(index: number) {
    util.removeFromArray(this.topic.ch, index)
  }
}
