import {UnitLevel, UnitModel, UnitStatus, UnitType} from "@/models/unit/model_unit"
import 'firebase/firestore'

export interface UnitPreviewModel {
  ti: string
  re?: string
  ty: UnitType
  st: UnitStatus
  le?: UnitLevel
  mi: number
  ma?: number
  sc: number
  ac: number
  er?: boolean
}

export default {
  buildFromUnit(unit: UnitModel): UnitPreviewModel {

    let numActions = 0
    for (let screen of unit.screens) {
      if (screen.action) numActions++
    }

    let json: UnitPreviewModel = {
      ti: unit.title,
      ty: unit.type,
      st: unit.status,
      sc: unit.screens.length,
      ac: numActions,
      mi: unit.minViTi,
    }

    if (unit.level !== undefined) {
      json.le = unit.level
    }

    if (unit.refId) {
      json.re = unit.refId
    }

    if (unit.maxViTi) {
      json.ma = unit.maxViTi
    }

    if (unit.error) {
      json.er = unit.error
    }

    return json
  },
  areUnitPreviewsEqual(u1: UnitPreviewModel, u2: UnitPreviewModel): boolean {
    return u1.ti === u2.ti &&
      u1.re === u2.re &&
      u1.ty === u2.ty &&
      u1.st === u2.st &&
      u1.le === u2.le &&
      u1.mi === u2.mi &&
      u1.ma === u2.ma &&
      u1.sc === u2.sc &&
      u1.ac === u2.ac &&
      u1.er === u2.er
  }
}