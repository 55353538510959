















































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import firebase from 'firebase/app'
import 'firebase/firestore'
import params from "@/app/params"
import Container from "@/components/fundamental/layout/Container.vue"
import {LogEvent} from "@/pages/fieldTestResults/model_log_events"
import LogEventDetails from "@/pages/fieldTestResults/LogEventDetails.vue"
import firestoreDocSize from "@/util/basic/firestoreDocSize"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"

@Component({
  components: {
    SpacerBox,
    EditNumber,
    InputField,
    LogEventDetails,
    Container, TextField, SectionLayout, TextButton, Row, Column}
})
export default class FieldTestResultsPage extends Vue {

  limit = 5
  newestEntries: Array<LogEvent> = []

  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get completedTests(): number {
    let c = 0
    for (let entry of this.newestEntries) {
      if (entry.events[entry.events.length-1].event === 'field_test_completed') {
        c++
      }
    }
    return c
  }



  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    // update navbar
    eventbus.$emit(EventType.navbar, new NavbarViewModel(true))

    // update sidePanel
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  async fetchEntries() {
    this.newestEntries = []

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore()
      .collectionGroup(params.firestore.logs2)
      .limit(Math.floor(this.limit))
      .orderBy('time', 'desc')
      .get()
    this.showLoading(false)

    querySnapshot.docs.map(doc => {
      // @ts-ignore
      let entry: LogEvent = doc.data()
      entry.id = doc.id
      entry.size = firestoreDocSize.calcDocSize(doc.data())
      this.newestEntries.push(entry)
    })
  }

  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
