




























































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {UnitLevel, UnitModel, UnitStatus, UnitStatusLabels, UnitType} from "@/models/unit/model_unit"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import UnitInfo from "@/pages/unit_editor/UnitInfo.vue"
import FirestoreInfo from "@/components/basic/FirestoreInfo.vue"
import CurriculumInfo from "@/pages/curriculum_viewer/CurriculumInfo.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import UnitExtendedInfo from "@/pages/unit_editor/UnitExtendedInfo.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import util from "@/util/util"
import store from "@/store/store"
import {eventbus} from "@/main"
import {EventType} from "@/components/app/eventModel"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue";
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue";
import TextButton from "@/components/fundamental/buttons/TextButton.vue"

@Component({
  components: {
    TextButton,
    BasicTextEditorDialog,
    AdvancedEditText,
    AdvancedCheckbox,
    AdvancedSelect,
    UnitExtendedInfo,
    TextField, SelectField, InputField, CurriculumInfo, FirestoreInfo, UnitInfo, SpacerBox, IconButton, EditText, Row, Column
  }
})
export default class UnitEditor extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({type: Array, default: undefined, required: false}) recordings!: Array<RecordingModel>

  unitStatus = UnitStatus
  unitStatusValues = util.enumToArray(UnitStatus)
  unitStatusLabels = util.enumToArray(UnitStatusLabels)
  unitTypeValues = util.enumToArray(UnitType)
  unitLevel = UnitLevel
  unitLevelValues = util.enumToArray(UnitLevel)


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {

  }


  ////////////////////////////////
  // Methods
  ////////////////////////////////
  async sendToApp() {
    await store.devSettings.sendUnitIdToApp(this.unit.id)
    eventbus.$emit(EventType.snackbar, {msg: 'Unit ID sent to app', duration: 2000})
  }

  copyLink() {
    util.unitLinkToClipboard(this.unit.id)
  }

  updateNotes(str: string) {
    if (str === '' && this.unit.notes !== undefined) {
      this.$delete(this.unit, 'notes')
    } else {
      this.$set(this.unit, 'notes', str)
    }
  }

  updateLevel(newLevel: UnitLevel) {
    this.$set(this.unit, 'level', newLevel)
  }

  removeLevel() {
    this.$delete(this.unit, 'level')
  }
}
