import firebase from 'firebase/app'
import Timestamp = firebase.firestore.Timestamp

export interface UserModel {
  id: string
  firstName: string
  lastName: string
  email: string
  image: string
  role: UserRole
  created: Timestamp
  lastLogin: Timestamp
  deleted?: boolean
}

export enum UserRole {
  admin = 'admin',
  tutor = 'tutor',
  user = 'user',
}