



































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import UnitChip from "@/pages/curriculum_viewer/UnitChip.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ColorPreview from "@/components/screen_editor/content/color/ColorPreview.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import MirandaLessons from "@/pages/curriculum_viewer/courses/MirandaLessons.vue"
import appState from "@/app/state/app_state"
import InlineColorSelector from "@/components/screen_editor/content/color/InlineColorSelector.vue"
import ContentEditor from "@/components/screen_editor/content/ContentEditor.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import InlineContentEditor from "@/components/screen_editor/content/InlineContentEditor.vue"
import {ChapterModel} from '@/models/curriculum/model_course'
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"

@Component({
  components: {
    AdvancedSelect,
    AdvancedEditText,
    InlineContentEditor,
    InputField, ContentEditor, InlineColorSelector, MirandaLessons, TextButton,
    ColorPreview, SpacerBox, Container, UnitChip, Draggable, RoundIconButton, Row, TextField, Column}
})
export default class MirandaChapter extends Vue {
  @Prop({type: Object, required: true}) chapter!: ChapterModel
  @Prop({type: Number, required: true}) chapterIndex!: number
  @Prop({type: Boolean, required: false, default: false}) isTestSection!: boolean

  curriculumState = appState.curriculum
  contentVisible: boolean = this.isTestSection ? !!this.curriculumState.mirandaChaptersVisible.get(this.chapter.id) :
    !!this.curriculumState.mirandaTestChaptersVisible.get(this.chapter.id)

  toggleContentVisible() {
    this.contentVisible = !this.contentVisible
    if (this.isTestSection) {
      this.curriculumState.mirandaChaptersVisible.set(this.chapter.id, this.contentVisible)
    } else {
      this.curriculumState.mirandaTestChaptersVisible.set(this.chapter.id, this.contentVisible)
    }
  }
}
