






























































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import util from '@/util/util'
import {RecordingModel} from "@/models/recording/model_recording"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import model_subtitle, {SubtitleModel} from "@/models/recording/model_subtitle"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"

@Component({
  components: {Container, TextButton, SpacerBox, TextField, IconButton, InputField, Row, Column}
})
export default class SubtitlesEditor extends Vue {
  @Prop({type: Object, required: true}) recording!: RecordingModel

  errorMsg = ''
  textFieldError = false
  rawTextFieldError = false

  subtitlesText = this.buildSubtitlesStr()
  lastSubtitlesText = this.buildSubtitlesStr()

  showRawTextField = false
  rawSubtitlesText = this.recording.subtitlesRaw.text

  /////////////////////////////////
  // Computed
  /////////////////////////////////
  get saveButtonEnabled() {
    return this.subtitlesText !== this.lastSubtitlesText
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  updateSubtitles() {
    this.updateText(this.subtitlesText)
  }

  resetSubtitles() {
    this.subtitlesText = this.lastSubtitlesText
  }

  loadRaw() {
    if (this.recording.subtitlesRaw.text) {
      this.showRawTextField = !this.showRawTextField

      if (this.showRawTextField) {
        this.rawSubtitlesText = this.recording.subtitlesRaw.text
      }
    }
  }

  convertRaw() {
    if (!this.rawSubtitlesText || !this.recording.subtitlesRaw.sentences) return

    this.errorMsg = ''
    this.rawTextFieldError = false

    let words_original = []
    for (let sentence of this.recording.subtitlesRaw.sentences) {
      words_original.push(...sentence.words)
    }

    let numWords_edited = 0
    let sentencesText = this.rawSubtitlesText.split('\n')
    let sentences_edited: Array<Array<String>> = []

    for (let sentence of sentencesText) {
      let words = sentence.trim().split(' ')

      // remove empty words
      for (let i = 0; i < words.length; i++) {
        if (words[i] === '') {
          words.splice(i, 1)
        }
      }

      numWords_edited += words.length
      sentences_edited.push(words)
    }

    if (numWords_edited != words_original.length) {
      this.errorMsg = 'Number of words in Raw Text changed (found: ' + numWords_edited + ', required: ' + words_original.length + ').'
      this.rawTextFieldError = true
      return
    }


    let resultStr = ''
    let index = 0
    for (let sentence of sentences_edited) {
      resultStr += util.createTimeString(words_original[index].start, false, true) + ' - '
      resultStr += util.createTimeString(words_original[index + sentence.length - 1].stop, false, true) + '\n'
      for (let word of sentence) {
        resultStr += word + ' '
      }
      resultStr = resultStr.trim()
      resultStr += '\n\n'
      index += sentence.length
    }

    this.updateText(resultStr)
  }

  updateText(text: string) {
    this.errorMsg = ''
    this.textFieldError = false

    let subtitles: Array<SubtitleModel> = []

    try {
      let rows = text.split('\n')

      let i = 0
      while (i < rows.length) {
        if (rows[i].length === 0) {
          i++
          continue
        }

        let subtitle = model_subtitle.template()
        let t = rows[i].split(' - ')
        subtitle.start = util.timeStrToNumber(t[0])
        subtitle.stop = util.timeStrToNumber(t[1])

        if (subtitles.length > 0 && subtitle.start < subtitles[subtitles.length - 1].stop) {
          throw 'Frames overlap'
        }

        if (subtitle.stop <= subtitle.start) {
          throw 'Start larger than stop'
        }

        while (rows[++i].length > 0) {
          subtitle.text += rows[i]
          if (i + 1 >= rows.length) break
        }

        subtitles.push(subtitle)
      }

      this.recording.subtitles = subtitles

      this.subtitlesText = this.buildSubtitlesStr()
      this.lastSubtitlesText = this.buildSubtitlesStr()

    } catch (e) {
      console.log(e)
      this.errorMsg = e
      this.textFieldError = true
    }
  }

  buildSubtitlesStr() {
    let str = ''
    for (let subtitle of this.recording.subtitles) {
      str += util.createTimeString(subtitle.start, false, true) + ' - '
      str += util.createTimeString(subtitle.stop, false, true) + '\n'
      str += subtitle.text + '\n\n'
    }
    return str
  }

}
