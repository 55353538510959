
























import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import MirandaChapters from "@/pages/curriculum_viewer/courses/MirandaChapters.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import model_course, {FormulasModel} from "@/models/curriculum/model_course";
import InlineColorSelector from "@/components/screen_editor/content/color/InlineColorSelector.vue";
import ColorPreview from "@/components/screen_editor/content/color/ColorPreview.vue";
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue";
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue";
import util from '@/util/util'
import Draggable from "@/components/fundamental/layout/Draggable.vue";
import MirandaLesson from "@/pages/curriculum_viewer/courses/MirandaLesson.vue";
import AddButton from "@/components/fundamental/buttons/AddButton.vue";
import {eventbus} from "@/main";
import {AlertDialogType, EventType} from "@/components/app/eventModel";
import MirandaFormulaChapter from "@/pages/curriculum_viewer/courses/MirandaFormulaChapter.vue";

@Component({
  components: {
    MirandaFormulaChapter,
    AddButton,
    MirandaLesson,
    Draggable,
    AdvancedSelect, AdvancedEditText, ColorPreview, InlineColorSelector,
    InputField, MirandaChapters, TextButton, SpacerBox, Container, RoundIconButton, Row, TextField, Column}
})
export default class MirandaFormulas extends Vue {
  @Prop({type: Object, required: true}) formulas!: FormulasModel

  addChapter() {
    this.formulas.ch.push(model_course.template_formulaChapter())
  }

  requestDeletingChapter(index: number) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete chapter`,
      text: `Delete the chapter: <b>${this.formulas.ch[index].ti}</b>?<br><br> You can restore this chapter by resetting the course. Changes will be permanent if you save the course.`,
      callback: (response: boolean) => {
        if (response) this.deleteChapter(index)
      }
    })
  }

  deleteChapter(index: number) {
    util.removeFromArray(this.formulas.ch, index)
  }
}
