


























































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import VideoPlayerWidget from "@/components/video/player/VideoPlayerWidget.vue"
import {VideoController, VideoControllerValue} from "@/components/video/controller/VideoController"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import util from "@/util/util"
import TextField from "@/components/fundamental/text/TextField.vue"
import Slider from "@/components/fundamental/inputs/Slider.vue"
import mMath from "@/util/mMath"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import {FlashlightPathModel, PathType, PenPathModel} from "@/models/recording/model_path"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import store from "@/store/store"

@Component({
  components: {
    TextButton,
    AdvancedSelect,
    AdvancedEditNumber,
    AdvancedEditText,
    TransitionEffect, IconButton, ScreenWidget, Slider, TextField, SpacerBox, VideoPlayerWidget, Container, Row, Column}
})
export default class PreviewPlayerDialog extends Vue {
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Object, required: true}) recording!: RecordingModel
  @Prop({type: Boolean, default: false, required: false}) linkedExercise!: boolean

  initialRecording: RecordingModel = util.copy(this.recording)

  showVideo = false
  isDialogVisible = false

  videoController: VideoController | null = null
  callbackId = -1

  width = 400
  height = 800
  time = 0
  isPlaying = false

  throttlePeriod = 100
  throttleBlocking = false
  targetProgress = 0


  get timeStr() {
    return util.createTimeString(this.time, false, true)
  }

  get durationStr() {
    let duration = this.videoController ? this.videoController.value.recording.duration : 0
    return util.createTimeString(duration, false, true)
  }

  get progress() {
    return this.videoController ? this.time / this.videoController.value.recording.duration : 0
  }

  get unsavedChanges() {
    return !util.compare(this.recording, this.initialRecording)
  }


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  beforeDestroy() {
    if (this.videoController && this.callbackId >= 0) {
      this.videoController.removeValueCallback(this.callbackId)
    }
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  open(showVideo: boolean) {
    this.showVideo = showVideo
    this.initialRecording = util.copy(this.recording)

    // calc max height
    this.recording.paths
    let y_max = 0;
    for (let p of this.recording.paths) {
      if (p.type === PathType.pen) {
        for (let y of (<PenPathModel>p).y) {
          y_max = Math.max(y, y_max)
        }
      } else if (p.type === PathType.flashlight) {
        for (let y of (<FlashlightPathModel>p).y) {
          y_max = Math.max(y, y_max)
        }
      }
    }
    this.height = Math.max(y_max * this.width + this.width/4, 800)

    // video controller
    if (!this.showVideo) {
      let r: RecordingModel = util.copy(this.recording)
      r.url = ''
      this.setVideoController(new VideoController(r, this.width, ''))
    }

    this.isDialogVisible = true
  }

  cancel() {
    if (this.unsavedChanges) {
      this.recording.playerAlignment = this.initialRecording.playerAlignment
      this.recording.playerZoomFactor = this.initialRecording.playerZoomFactor
    }
    this.isDialogVisible = false
  }


  setVideoController(videoController: VideoController) {
    this.callbackId = videoController.addValueCallback((v: VideoControllerValue) => {
      this.time = v.time
      this.isPlaying = v.isPlaying
    })
    this.videoController = videoController
  }

  togglePlayer() {
    if (this.videoController) {
      this.videoController.togglePlay()
    }
  }

  setProgress(progress: number) {
    this.targetProgress = progress

    if (!this.throttleBlocking) {
      this.throttleBlocking = true

      setTimeout(() => {
        if (this.videoController) {
          let time = mMath.constrain(this.targetProgress, 0, 1) * this.videoController.value.recording.duration
          this.videoController.setTime(time)
        }
        this.throttleBlocking = false
      }, this.throttlePeriod)
    }
  }

  saveRecording() {
    store.recordings.updateRemoteRecording(this.recording)
    this.initialRecording = util.copy(this.recording)
  }
}
