














































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import UnitChip from "@/pages/curriculum_viewer/UnitChip.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ColorPreview from "@/components/screen_editor/content/color/ColorPreview.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import util from "@/util/util"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import {FormulaChapterModel} from '@/models/curriculum/model_course'
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue"

@Component({
  components: {
    BasicTextEditorDialog,
    AdvancedEditText,
    SelectField, InputField, TextButton,
    ColorPreview, SpacerBox, Container, UnitChip, Draggable, RoundIconButton, Row, TextField, Column}
})
export default class MirandaFormulaChapter extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) chapter!: FormulaChapterModel


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  removeUnit(unitIndex: number) {
    util.removeFromArray(this.chapter.un, unitIndex)
  }
}
