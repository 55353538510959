import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

import LoginPage from '../pages/login/LoginPage.vue'
import CurriculumPage from "@/pages/curriculum_viewer/CurriculumPage.vue"
import CurriculumEditorPage from "@/pages/curriculum_editor/CurriculumEditorPage.vue"
import TestAreaPage from "@/test_area/TestAreaPage.vue"
import HtmlOverview from "@/test_area/html/HtmlOverview.vue"
import RecordingOverview from "@/test_area/recording/RecordingOverview.vue"
import UnitEditorOverView from "@/test_area/unit_editor/UnitEditorOverview.vue"
import FirestoreOverview from "@/test_area/firestore/FirestoreOverview.vue"
import TestRecording_camera from "@/test_area/recording/TestRecording_camera.vue"
import TestHtml_buttons from "@/test_area/html/TestHtml_buttons.vue"
import TestHtml_dialogs from "@/test_area/html/TestHtml_dialogs.vue"
import TestHtml_icons from "@/test_area/html/TestHtml_icons.vue"
import TestFirestore_code from "@/test_area/firestore/TestFirestore_code.vue"
import TestFirestore_entries from "@/test_area/firestore/TestFirestore_entries.vue"
import TestHtml_imageUpload from "@/test_area/html/TestHtml_imageUpload.vue"
import TestHtml_gifSelector from "@/test_area/html/TestHtml_gifSelector.vue"
import TestSimulator_screen from "@/test_area/simulator/TestSimulator_screen.vue"
import SimulatorOverview from "@/test_area/simulator/SimulatorOverview.vue"
import TestSimulator_displaySizes from "@/test_area/simulator/TestSimulator_displaySizes.vue"
import TestUnitEditor_staticContent from "@/test_area/unit_editor/TestUnitEditor_staticContent.vue"
import TestUnitEditor_actionContent from "@/test_area/unit_editor/TestUnitEditor_actionContent.vue"
import TestUnitEditor_actionResponses from "@/test_area/unit_editor/TestUnitEditor_actionResponses.vue"
import TestUnitEditor_actionMc from "@/test_area/unit_editor/TestUnitEditor_actionMc.vue"
import TestUnitEditor_actionTable from "@/test_area/unit_editor/TestUnitEditor_actionTable.vue"
import TestRecording_multipleVideos from "@/test_area/recording/TestRecording_multipleVideos.vue"
import TestRecording_recorder from "@/test_area/recording/TestRecording_recorder.vue"
import TestRecording_videoEditor from "@/test_area/recording/TestRecording_videoEditor.vue"
import TestRecording_videoPlayer from "@/test_area/recording/TestRecording_videoPlayer.vue"
import TestRecording_drawingboard from "@/test_area/recording/TestRecording_drawingboard.vue"
import ConverterPage from "@/pages/converter/ConverterPage.vue"
import TestRecording_recorderButton from "@/test_area/recording/TestRecording_recorderButton.vue"
import TestRecording_videoEditorDialog from "@/test_area/recording/TestRecording_videoEditorDialog.vue"
import RecorderWindow from '@/pages/recorder/RecorderWindow.vue'
import TestSimulator_uiElements from "@/test_area/simulator/TestSimulator_uiElements.vue"
import TestSimulator_mobileUnit from "@/test_area/simulator/TestSimulator_mobileUnit.vue"
import UnitEditorPage from "@/pages/unit_editor/UnitEditorPage.vue"
import TestUnitEditor_unitEditor from "@/test_area/unit_editor/TestUnitEditor_unitEditor.vue"
import TestRecording_previewPlayer from "@/test_area/recording/TestRecording_previewPlayer.vue"
import TestRecording_drawingboard2 from "@/test_area/recording/TestRecording_drawingboard2.vue"
import TestUnitEditor_actionDragDrop from "@/test_area/unit_editor/TestUnitEditor_actionDragDrop.vue"
import TestUnitEditor_actionDragConnect from "@/test_area/unit_editor/TestUnitEditor_actionDragConnect.vue"
import store from "@/store/store"
import TestHtml_contentEditor from "@/test_area/html/TestHtml_contentEditor.vue"
import SearchPage from "@/pages/search/SearchPage.vue"
import ConverterWindow from "@/pages/converter/ConverterWindow.vue"
import FieldTestResultsPage from "@/pages/fieldTestResults/FieldTestResultsPage.vue"
import OldVersionsPage from "@/pages/old_versions/OldVersionsPage.vue"
import TestUnitEditor_staticTitle from "@/test_area/unit_editor/TestUnitEditor_staticTitle.vue"
import TestUnitEditor_actionInput from "@/test_area/unit_editor/TestUnitEditor_actionInput.vue"
import RegExpEditor from "@/pages/regexp_editor/RegExpEditor.vue"
import TestHtml_playground from "@/test_area/html/TestHtml_playground.vue"
import SoundsPage from "@/pages/sounds/SoundsPage.vue"

Vue.use(VueRouter)

export enum RouteName {
  home = 'home',
  login = 'login',
  curriculum = 'curriculum',
  curriculumEditor = 'curriculumEditor',
  unitEditor = 'unitEditor',
  recorderWindow = 'recorderWindow',
  converter = 'converter',
  converterWindow = 'converterWindow',
  search = 'search',
  fieldTestResults = 'fieldTestResults',
  regExpEditor = 'regExpEditor',
  oldVersions = 'oldVersions',
  sounds = 'sounds',

  // testing
  testing = 'testing',

  testing_recording = 'testing_recording',
  testing_recording_camera = 'testing_recording_camera',
  testing_recording_drawingboard = 'testing_recording_drawingboard',
  testing_recording_recorder = 'testing_recording_recorder',
  testing_recording_recorderButton = 'testing_recording_recorderButton',
  testing_recording_multipleVideos = 'testing_recording_multipleVideos',
  testing_recording_videoEditor = 'testing_recording_videoEditor',
  testing_recording_videoEditorDialog = 'testing_recording_videoEditorDialog',
  testing_recording_videoPlayer = 'testing_recording_videoPlayer',
  testing_recording_previewPlayer = 'testing_recording_previewPlayer',

  testing_recording_drawingboard2 = 'testing_recording_drawingboard2',

  testing_firestore = 'testing_firestore',
  testing_firestore_entries = 'testing_firestore_entries',
  testing_firestore_code = 'testing_firestore_code',

  testing_unitEditor = 'testing_unitEditor',
  testing_unitEditor_unitEditor = 'testing_unitEditor_unitEditor',
  testing_unitEditor_staticTitle = 'testing_unitEditor_staticTitle',
  testing_unitEditor_staticContent = 'testing_unitEditor_staticContent',
  testing_unitEditor_actionContent = 'testing_unitEditor_actionContent',
  testing_unitEditor_actionDragConnect = 'testing_unitEditor_dragConnect',
  testing_unitEditor_actionDragDrop = 'testing_unitEditor_dragDrop',
  testing_unitEditor_actionInput = 'testing_unitEditor_actionInput',
  testing_unitEditor_actionMc = 'testing_unitEditor_actionMc',
  testing_unitEditor_actionTable = 'testing_unitEditor_actionTable',
  testing_unitEditor_actionResponses = 'testing_unitEditor_actionResponses',

  testing_simulator = 'testing_simulator',
  testing_simulator_screen = 'testing_simulator_screen',
  testing_simulator_mobileUnit = 'testing_simulator_mobileUnit',
  testing_simulator_displaySizes = 'testing_simulator_displaySizes',
  testing_simulator_uiElements = 'testing_simulator_uiElements',

  testing_html = 'testing_html',
  testing_html_buttons = 'testing_html_buttons',
  testing_html_dialogs = 'testing_html_dialogs',
  testing_html_icons = 'testing_html_icons',
  testing_html_imageUpload = 'testing_html_imageUpload',
  testing_html_gifSelector = 'testing_html_gifSelector',
  testing_html_contentEditor = 'testing_html_contentEditor',
  testing_html_playground = 'testing_html_playground',
}

const routes: Array<RouteConfig> = [

  /////////////////////////////////
  // Units
  /////////////////////////////////
  {
    name: RouteName.curriculum,
    path: '/curriculum',
    component: CurriculumPage
  },
  {
    name: RouteName.curriculumEditor,
    path: '/curriculum-editor',
    component: CurriculumEditorPage,
  },
  {
    name: RouteName.unitEditor,
    path: '/unit-editor/:id',
    component: UnitEditorPage,
  },
  {
    name: RouteName.recorderWindow,
    path: '/recorder-window',
    component: RecorderWindow,
  },
  {
    name: RouteName.converter,
    path: '/converter',
    component: ConverterPage
  },
  {
    name: RouteName.converterWindow,
    path: '/converter-window',
    component: ConverterWindow,
  },
  {
    name: RouteName.search,
    path: '/search',
    component: SearchPage
  },
  {
    name: RouteName.fieldTestResults,
    path: '/field-test-results',
    component: FieldTestResultsPage
  },
  {
    name: RouteName.regExpEditor,
    path: '/regexp-editor',
    component: RegExpEditor
  },
  {
    name: RouteName.oldVersions,
    path: '/old-versions',
    component: OldVersionsPage
  },
  {
    name: RouteName.sounds,
    path: '/sounds',
    component: SoundsPage
  },

  /////////////////////////////////
  // Login
  /////////////////////////////////
  {
    name: RouteName.login,
    path: '/login',
    component: LoginPage
  },

  /////////////////////////////////
  // Test
  /////////////////////////////////
  {
    name: RouteName.testing,
    path: '/testing',
    component: TestAreaPage,
    children: [
      {
        path: '/testing/recording',
        name: RouteName.testing_recording,
        component: RecordingOverview,
        children: [
          {
            path: '/testing/recording/camera',
            name: RouteName.testing_recording_camera,
            component: TestRecording_camera
          },
          {
            path: '/testing/recording/drawingboard',
            name: RouteName.testing_recording_drawingboard,
            component: TestRecording_drawingboard
          },
          {
            path: '/testing/recording/drawingboard2',
            name: RouteName.testing_recording_drawingboard2,
            component: TestRecording_drawingboard2
          },
          {
            path: '/testing/recording/recorder-button',
            name: RouteName.testing_recording_recorderButton,
            component: TestRecording_recorderButton
          },
          {
            path: '/testing/recording/multiple-videos',
            name: RouteName.testing_recording_multipleVideos,
            component: TestRecording_multipleVideos
          },
          {
            path: '/testing/recording/recorder',
            name: RouteName.testing_recording_recorder,
            component: TestRecording_recorder
          },
          {
            path: '/testing/recording/video-editor',
            name: RouteName.testing_recording_videoEditor,
            component: TestRecording_videoEditor
          },
          {
            path: '/testing/recording/video-editor-dialog',
            name: RouteName.testing_recording_videoEditorDialog,
            component: TestRecording_videoEditorDialog
          },
          {
            path: '/testing/recording/video-player',
            name: RouteName.testing_recording_videoPlayer,
            component: TestRecording_videoPlayer
          },
          {
            path: '/testing/recording/preview-player',
            name: RouteName.testing_recording_previewPlayer,
            component: TestRecording_previewPlayer
          },
        ]
      },
      {
        path: '/testing/unit-editor',
        name: RouteName.testing_unitEditor,
        component: UnitEditorOverView,
        children: [
          {
            path: '/testing/unit-editor/unit-editor',
            name: RouteName.testing_unitEditor_unitEditor,
            component: TestUnitEditor_unitEditor
          },
          {
            path: '/testing/unit-editor/static-content',
            name: RouteName.testing_unitEditor_staticContent,
            component: TestUnitEditor_staticContent
          },
          {
            path: '/testing/unit-editor/static-title',
            name: RouteName.testing_unitEditor_staticTitle,
            component: TestUnitEditor_staticTitle
          },
          {
            path: '/testing/unit-editor/action-content',
            name: RouteName.testing_unitEditor_actionContent,
            component: TestUnitEditor_actionContent
          },
          {
            path: '/testing/unit-editor/action-responses',
            name: RouteName.testing_unitEditor_actionResponses,
            component: TestUnitEditor_actionResponses
          },
          {
            path: '/testing/unit-editor/action-drag-connect',
            name: RouteName.testing_unitEditor_actionDragConnect,
            component: TestUnitEditor_actionDragConnect
          },
          {
            path: '/testing/unit-editor/action-drag-drop',
            name: RouteName.testing_unitEditor_actionDragDrop,
            component: TestUnitEditor_actionDragDrop
          },
          {
            path: '/testing/unit-editor/action-input',
            name: RouteName.testing_unitEditor_actionInput,
            component: TestUnitEditor_actionInput
          },
          {
            path: '/testing/unit-editor/action-mc',
            name: RouteName.testing_unitEditor_actionMc,
            component: TestUnitEditor_actionMc
          },
          {
            path: '/testing/unit-editor/action-table',
            name: RouteName.testing_unitEditor_actionTable,
            component: TestUnitEditor_actionTable
          },
        ]
      },
      {
        path: '/testing/simulator',
        name: RouteName.testing_simulator,
        component: SimulatorOverview,
        children: [
          {
            path: '/testing/simulator/screen',
            name: RouteName.testing_simulator_screen,
            component: TestSimulator_screen
          },
          {
            path: '/testing/simulator/mobile-unit',
            name: RouteName.testing_simulator_mobileUnit,
            component: TestSimulator_mobileUnit
          },
          {
            path: '/testing/simulator/display-sizes',
            name: RouteName.testing_simulator_displaySizes,
            component: TestSimulator_displaySizes
          },
          {
            path: '/testing/simulator/ui-elements',
            name: RouteName.testing_simulator_uiElements,
            component: TestSimulator_uiElements
          },
        ]
      },
      {
        path: '/testing/firestore',
        name: RouteName.testing_firestore,
        component: FirestoreOverview,
        children: [
          {
            path: '/testing/firestore/entries',
            name: RouteName.testing_firestore_entries,
            component: TestFirestore_entries
          },
          {
            path: '/testing/firestore/code',
            name: RouteName.testing_firestore_code,
            component: TestFirestore_code
          },
        ]
      },
      {
        path: '/testing/html',
        name: RouteName.testing_html,
        component: HtmlOverview,
        children: [
          {
            path: '/testing/html/buttons',
            name: RouteName.testing_html_buttons,
            component: TestHtml_buttons
          },
          {
            path: '/testing/html/dialogs',
            name: RouteName.testing_html_dialogs,
            component: TestHtml_dialogs
          },
          {
            path: '/testing/html/icons',
            name: RouteName.testing_html_icons,
            component: TestHtml_icons
          },
          {
            path: '/testing/html/image-upload',
            name: RouteName.testing_html_imageUpload,
            component: TestHtml_imageUpload
          },
          {
            path: '/testing/html/gif-selector',
            name: RouteName.testing_html_gifSelector,
            component: TestHtml_gifSelector
          },
          {
            path: '/testing/html/content-editor',
            name: RouteName.testing_html_contentEditor,
            component: TestHtml_contentEditor
          },
          {
            path: '/testing/html/playground',
            name: RouteName.testing_html_playground,
            component: TestHtml_playground
          },
        ]
      },

    ]
  },

  /////////////////////////////////
  // Default
  /////////////////////////////////
  {
    name: RouteName.home,
    path: '/',
    redirect: {name: 'curriculum'}
  },
  {
    path: '*',
    redirect: {name: 'curriculum'}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (!store.auth.signedIn && to.name !== RouteName.login) {
    next({name: RouteName.login, query: {redirect: to.fullPath}})
  } else {
    next()
  }
})

export default router